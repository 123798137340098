import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/do';
import { throwError } from 'rxjs';

import { Router } from '@angular/router';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';

import { environment } from '../../environments/environment';
import { UserService } from './user.service';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  public API_ENDPOINT = environment.apiUrl + 'api/';

  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  /**
   * Take any HTTP request made with HttpClient and add the proper outgoing headers for authentication purposes.
   * @param req
   * @param next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // modify the request
    const authReq = req.clone({
      headers: req.headers
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123"))
       // .set('accessToken', '' +this.getToken())

    });
    // send to the next interceptor or to its final destination
    return next.handle(authReq).catch((error: HttpErrorResponse) => {
      // return Observable.throw(error);
      if (error instanceof HttpErrorResponse && error.status === 401) {
        this.userService.handle401Error(true);
      }
      return throwError(error);
    });
  }

  /**
   * get the jwt token that was saved to local storage when authentication happened.
   * @returns {string|null}
   */
  protected getToken() {
    return localStorage.getItem(environment.localSTORAGE.token);
  }

}
