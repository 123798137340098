import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errorMSG'
})
export class ErrorMsgPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    
    let errorMessage;
    switch (value) {
      case 'email':
        if (args[0] === 'required') {
          errorMessage = 'Email field is required.';
        }
        if (args[0] === 'email') {
          errorMessage = 'Enter valid email address.';
        }
        break;

      case 'password':
        if (args[0] === 'required') {
          errorMessage = 'Password field is required.';
        }
        if (args[0] === 'minlength') {
          errorMessage = 'Enter minimum 6 character.';
        }
        break;
    }
    return errorMessage;
  }

}
