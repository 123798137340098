import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: 'match',
    loadChildren: () => import('./match/match.module').then((m) => m.MatchModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user-management/user-management.module').then((m) => m.UserManagementModule)
  },
  {
    path: 'quiz',
    loadChildren: () => import('./quiz/quiz.module').then((m) => m.QuizModule)
  },
  {
    path: 'poll',
    loadChildren: () => import('./polls/polls.module').then((m) => m.PollsModule)
  },
  {
    path: 'venue',
    loadChildren: () => import('./venue/venue.module').then((m) => m.VenueModule)
  },
  {
    path:'player',
    loadChildren : () => import('./player/player.module').then((m)=>m.PlayerModule)
  },
  {
    path: 'ads',
    loadChildren: () => import('./ads/ads.module').then((m) => m.AdsModule)
  },
  {
    path: 'role',
    loadChildren: () => import('./role-management/role-management.module').then((m) => m.RoleManagementModule)
  },
  {
    path: 'sub-admin',
    loadChildren: () => import('./sub-admin/sub-admin.module').then((m) => m.SubAdminModule)
  },
  {
    path: 'competition',
    loadChildren: () => import('./competition/competition.module').then((m) => m.CompetitionModule)
  },
  {
    path: 'point-system',
    loadChildren: () => import('./point-system/point-system.module').then((m) => m.PointSystemModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./subscription/subscription.module').then((m) => m.SubscriptionModule)
  },
  {
    path: 'subscription-gift',
    loadChildren: () => import('./gift-subscription/gift-subscription.module').then((m) => m.GiftSubscriptionModule)
  },
  {
    path: 'plan',
    loadChildren: () => import('./plan-management/plan-management.module').then((m) => m.PlanManagementModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./custom-notification/custom-notification.module').then((m) => m.CustomNotificationModule)
  },
  {
    path: 'company-information',
    loadChildren: () => import('./company-information/company-information.module').then((m) => m.CompanyInformationModule)
  },
  {
    path: 'advertise',
    loadChildren: () => import('./advertise/advertise.module').then((m) => m.AdvertiseModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule)
  },
  {
    path: 'payment-page',
    loadChildren: () => import('./payment-page/payment-page.module').then((m) => m.PaymentPageModule)
  },
  {
    path: 'reward-point-management',
    loadChildren: () => import('./reward-point-managenent/reward-point-managenent.module').then((m) => m.RewardPointManagenentModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule)
  },
  {
    path: 'public',
    loadChildren: () => import('./web-module/web-module.module').then((m) => m.WebModuleModule)
  },
  {
    path: 'scrapping',
    loadChildren: () => import('./scrapping/scrapping.module').then((m) => m.ScrappingModule)
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
