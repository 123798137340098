import { Directive, ElementRef, HostListener, Renderer2, Input } from '@angular/core';
import { NotificationService, UserService } from '../services';

@Directive({
  selector: '[appActiveButtonDirec]'
})
export class ActiveButtonDirecDirective {
  @Input() buttonsValue: String;
  public isLoading: Boolean = false;
  public scoreArray: Array<any> = [1];

  public matchObj: any;
  constructor(private el: ElementRef, private renderer: Renderer2, private notificationService: NotificationService, private userService: UserService) {
  }
  @HostListener('click') OnButtonsActive(event: Event) {
    if (this.renderer.parentNode(this.el.nativeElement).classList.contains("wicketDirclass")) {
      for (let index = 0; index < this.renderer.parentNode(this.el.nativeElement).children.length; index++) {
        if ((this.renderer.parentNode(this.el.nativeElement).children[index]).classList.contains('active')) {
          this.renderer.removeClass(this.renderer.parentNode(this.el.nativeElement).children[index], 'active');
        }
      }
      this.renderer.addClass(this.el.nativeElement, 'active');
    }
    else {
      if ((this.el.nativeElement.innerHTML).trim() == 'Done') {
      } else {
        if (this.el.nativeElement.classList.contains('active')) {
          this.renderer.removeClass(this.el.nativeElement, 'active');
        }
        else {
          this.renderer.addClass(this.el.nativeElement, 'active');
          this.renderer.parentNode(this.el.nativeElement);
        }
      }

    }
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.userService.matchObjSubject.subscribe((data:any)=>{
    // })
  }

}
