import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AppstorageService {

  constructor() { }
  setdata(key, value,type='') {
    value = type === 'token' ? value : this.encryptdata(value);
    localStorage.setItem(key, value);
  } // end of the setdata function
  getdata(key) {
    if (localStorage.getItem(key)) {
      return (this.decryptdata(localStorage.getItem(key)));
    }
  } // end of the getdata function
  removedata(key) {
    return localStorage.removeItem(key);
  } // end of the removedata function
  cleardata() {
    return localStorage.clear();
  } // end of the cleardata function
  // Encrypt data
  encryptdata(values) {
    return CryptoJS.AES.encrypt(values, 'RaInPayAdMiN').toString();
  }
  // Decrypt your data by key name
  decryptdata(values) {
    const bytes = CryptoJS.AES.decrypt(values, 'RaInPayAdMiN');
    return bytes.toString(CryptoJS.enc.Utf8); // Utf8
  }
  // get selected language of user
  langdata() {
    return this.getdata('selectedLang') ? this.getdata('selectedLang') : 'en';
  }
}
