import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
@Injectable()
export class NotificationService {
  constructor(
    private toastr: ToastrService,
    private title: Title,
  ) {
  }

  public showTitle(titleName) {
    titleName = 'crickRadio | ' + titleName;
    this.title.setTitle(titleName);
  }
  public show(response: any, type: boolean = false) {
    const errorMessageArray: any = response.split('.');
    errorMessageArray.forEach((eachObj) => {
      if (eachObj !== '') {
        if (type) {
          this.toastr.success(eachObj);
        } else {
          this.toastr.error(eachObj);
        }
      }
    });
  }
  public showWarning(msg){
    this.toastr.warning(msg)
  }
  headerResponce(responceData) {
    const resSTR = JSON.stringify(responceData);
    const resJSON = JSON.parse(resSTR);
    return JSON.parse(resJSON._body);
  }
}
