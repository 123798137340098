import { Component, ElementRef, OnInit, ViewChild, Renderer2, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService, UserService } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import io from 'socket.io-client/dist/socket.io';
import { SocketioService } from 'src/app/services/socketio.service';
/**********************************imports module of charts *************************************/
import { Chart, registerables } from 'chart.js';
@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.css']
})
export class ButtonsComponent implements OnInit {
  title = 'socketio-angular';
  @ViewChild('eventDetails', { static: false }) eventDetails: TemplateRef<any>;
  @ViewChild('penalty', { static: false }) penalty: TemplateRef<any>;
  @ViewChild('wagonWheel', { static: false }) wagonWheel: TemplateRef<any>;
  @ViewChild('review', { static: false }) review: TemplateRef<any>;
  @ViewChild('break', { static: false }) break: TemplateRef<any>;
  public isLoading: Boolean = false;
  public dropdownSettings: any = {};
  public isReadOnly: Boolean = false;
  public isWicketActive: Boolean = false;
  public teams: Array<any> = [];
  public buttonsValueObj = {
    legbye: "legbye",
    one: 1,
    zero: 0,
    two: 2,
    three: 3,
    four: 4,
    six: 6,
    noball: 'noball',
    done: 'done',
  };
  public changeRequestObject = {
    'showCommentry': false,
    'change_Bowler_Batsman': false,
  }
  public params = {
    currentInning: -1,
    currentBatsman: "",
    currentTeam: "",
    nonStrikerBatsman: "",
    mute: false,
    wwmute: false,
    commentryStringArray: [],
    review: "",
    ballTypeAndRun: {
      wicket: -1,
      wicketType: "",
      speechWicket: "",
      scorecardCom: "",
      outType: "",
      fielderName: "",
      normal: -1,
      wide: -1,
      bye: -1,
      legbye: -1,
      noball: -1,
      overthrow: -1,
      shortRun: -1
    },
    matchKey: "",
    outBatsman: "",
    currentBowler: "",
    reviewTakenByTeam: "",
    isReviewLost: false
  }
  public wragonWheelObj = {
    "batsman_direction": "",
    "bowler_direction": "",
    "matchKey": "",
    "currentTeam": "",
    "currentInning": "",
    "bowler_right_left_direction": ""
  }
  value: any;
  public bowler: Array<any> = [];
  public buttonDisable = true;
  public buttonNumberDisable = false;
  /*******************  Array To Store Buttons Value*************************** */
  public commentryStringArray: Array<any> = [];
  public scoreNumberArray: Array<any> = [];
  public scoreStringArray: Array<any> = [];
  public wicketReasonArray: Array<any> = [];
  public shortStringArray = [];
  public reviewStringArray: Array<any> = [];
  public extraButtonArray: Array<any> = [];
  /*******************  End*************************** */
  public matchDetailObject: any = {};
  public selectedItems: Array<any> = [];
  wicketForm: any;
  public breakForm: any;
  public penaltyForm: any;
  public reviewSecondRow = -1;
  public reviewThirdRow = -1;
  /**********  Variable declare for charts**************** */
  PieChart
  constructor(private socketService: SocketioService, private fb: FormBuilder, private notificationService: NotificationService, private userService: UserService, private renderer: Renderer2, private modalService: NgbModal) {
    this.wicketForm = new FormGroup({
      batsman: new FormControl(null, [Validators.required]),
      bowler: new FormControl(null),
    });
    this.penaltyForm = new FormGroup({
      currentInning: new FormControl(null),
      runsGivenToInning: new FormControl(null, [Validators.required]),
      currentTeam: new FormControl(null),
      runsGivenToTeam: new FormControl(null, [Validators.required]),
      reason: new FormControl(null, [Validators.required]),
      run: new FormControl(null, [Validators.required]),
      isAddDelivery: new FormControl(null),
      isAddBatsman: new FormControl(null)
    })
    this.breakForm = new FormGroup({
      name: new FormControl(null, [Validators.required])
    })
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false,
    };
  }
  ngOnInit(): void {
   // this.wicketForm.get('batsman').setValue('batsman1');
    this.matchDataPassed();
    sessionStorage.setItem('changeRequestObject', JSON.stringify(this.changeRequestObject));
  }
  formActionAddEdit(data) {
  }
  sendWragonWheelData() {
    this.isLoading = true;
    if (localStorage.getItem('batsman_direction')) {
      this.wragonWheelObj.batsman_direction = (JSON.parse(localStorage.getItem('batsman_direction'))).batting_index;
    }
    this.userService.addWragonWheel(this.wragonWheelObj).then((responce) => {
      if (responce.statusCode == 1) {
        this.closeModal();
        this.isLoading = false;
      }
      else {
        this.isLoading = false;
        this.notificationService.show("Error occured");
      }
    }).catch((err) => {
     
      this.isLoading = false;
    })
  }
  resetWrangonWheelObject() {
    this.wragonWheelObj.batsman_direction = "";
    this.wragonWheelObj.bowler_direction = "";
    this.wragonWheelObj.bowler_right_left_direction = "";
    this.wragonWheelObj.currentInning = "";
    this.wragonWheelObj.currentTeam = "";
    this.wragonWheelObj.matchKey = "";
    localStorage.removeItem("batsman_direction");
  }
  pieChartBrowser(data) {
    let lebels = [];
    let backgroundColor = [];
    if (data == 'Left-Hand Bat') {
      lebels = ['Third Man', 'Point', 'Cover', 'Long-Off', 'Long-On', 'Mid-Wicket', 'Square leg', 'Fine leg'];
      backgroundColor = [
        'rgba(255,255,0,0.9)',
        'rgba(192,255,0,0.9)',
        'rgba(0,0,128,0.9)',
        'rgba(0,255,255,0.9)',
        'rgba(192,192,192,0.9)',
        'rgba(239,23,240,0.9)',
        'rgba(0,128,0,0.9)',
        'rgba(40,23,244,0.9)',
      ]
    }
    else {
      lebels = ['Fine leg', 'Square leg', 'Mid-Wicket', 'Long-On', 'Long-Off', 'Cover', 'Point', 'Third Man'],
        backgroundColor = [
          'rgba(40,23,244,0.9)',
          'rgba(0,128,0,0.9)',
          'rgba(239,23,240,0.9)',
          'rgba(192,192,192,0.9)',
          'rgba(0,255,255,0.9)',
          'rgba(0,0,128,0.9)',
          'rgba(192,255,0,0.9)',
          'rgba(255,255,0,0.9)'
        ]
    }
    Chart.register(...registerables)
    let PieChart: any = new Chart('pieChart', {
      type: 'pie',
      data: {
        labels: lebels,
        datasets: [{
          label: 'Vote Now',
          data: [15, 10, 10, 15, 15, 10, 10, 15],
          backgroundColor: backgroundColor,
        }]
      },
      options: {
        responsive: true,
        onClick: function (c, i) {
          let backgroundColor = [];
          if (data == 'Left-Hand Bat') {
            backgroundColor = [
              'rgba(255,255,0,0.9)',
              'rgba(192,255,0,0.9)',
              'rgba(0,0,128,0.9)',
              'rgba(0,255,255,0.9)',
              'rgba(192,192,192,0.9)',
              'rgba(239,23,240,0.9)',
              'rgba(0,128,0,0.9)',
              'rgba(40,23,244,0.9)',
            ]
          }
          else {
            backgroundColor = [
              'rgba(40,23,244,0.9)',
              'rgba(0,128,0,0.9)',
              'rgba(239,23,240,0.9)',
              'rgba(192,192,192,0.9)',
              'rgba(0,255,255,0.9)',
              'rgba(0,0,128,0.9)',
              'rgba(192,255,0,0.9)',
              'rgba(255,255,0,0.9)'
            ]
          }
          /****************************  Logic for getting clicked Index************************************** */
          let e = i[0];
          localStorage.setItem('batsman_direction', JSON.stringify({ 'batting_index': `${e.index}` }));
          // /*****************************Logic For Updating color on clicked******************************* */
          for (var ind = 0; ind < PieChart.config._config.data.datasets[0].backgroundColor.length; ind++) {
            if (ind == e.index)
              PieChart.config._config.data.datasets[0].backgroundColor[ind] = 'red';
            else {
              PieChart.config._config.data.datasets[0].backgroundColor[ind] = backgroundColor[ind];
            }
          }
          PieChart.update();
        },
      }
    });
  }
  BowlerAndBatsManInitials() {
    let bowlerName = ""
    let currentBowler = ""
    let fielderName = "";
    let firstFielder = "";
    let secondFielder = "";
    /***************     Logic For Bowler Name Initials   ********************** */
    bowlerName = (this.matchDetailObject.now.bowler.name).split(' ');
    for (let index = 0; index < bowlerName.length; index++) {
      if (index < bowlerName.length - 1) {
        if (bowlerName[index].length == 2) {
          currentBowler = (`${currentBowler} ${bowlerName[index]}`).toString().toUpperCase();
        }
        else {
          currentBowler = (`${currentBowler}${bowlerName[index].charAt(0)}`).toString().toUpperCase();
        }
      }
      else if (index == bowlerName.length - 1) {
        currentBowler = (`${currentBowler} ${bowlerName[bowlerName.length - 1]}`).toString();
      }
    }
    /*******************    Logic For First Fielder  Initials***************** */
    if (this.wicketForm.value.bowler.length >= 1) {
      fielderName = (this.wicketForm.value.bowler[0].name).split(' ');
      for (let index = 0; index < fielderName.length; index++) {
        if (index < fielderName.length - 1) {
          if (fielderName[index].length == 2) {
            firstFielder = (`${firstFielder} ${fielderName[index]}`).toString().toUpperCase();
          }
          else {
            firstFielder = (`${firstFielder}${fielderName[index].charAt(0)}`).toString().toUpperCase();
          }
        }
        else if (index == fielderName.length - 1) {
          firstFielder = (`${firstFielder} ${fielderName[fielderName.length - 1]}`).toString();
        }
      }
    }
    /*******************    Logic For Second Fielder  Initials***************** */
    if (this.wicketForm.value.bowler.length >= 2) {
      fielderName = (this.wicketForm.value.bowler[1].name).split(' ');
      for (let index = 0; index < fielderName.length; index++) {
        if (index < fielderName.length - 1) {
          if (fielderName[index].length == 2) {
            secondFielder = (`${secondFielder} ${fielderName[index]}`).toString().toUpperCase();
          }
          else {
            secondFielder = (`${secondFielder}${fielderName[index].charAt(0)}`).toString().toUpperCase();
          }
        }
        else if (index == fielderName.length - 1) {
          secondFielder = (`${secondFielder} ${fielderName[fielderName.length - 1]}`).toString();
        }
      }
    }
    return [currentBowler, firstFielder, secondFielder];
  }
  getButtonValue(data: any, event: any = {}) {
    debugger
    if (typeof (data) == 'string') {
      switch (data.trim()) {
        case 'legbye': case 'bye': case 'wide': case 'noball': case 'overthrow': case 'shortRun': {
          if (this.scoreStringArray.includes(data.trim())) {
          }
          else {
            if ((data.trim()) === "legbye") {
              this.shortStringArray.push("LB")
            }
            else if ((data.trim()) === "noball") {
              this.shortStringArray.push("NB")
            }
            else if ((data.trim()) === "wide") {
              this.shortStringArray.push("wd")
            }
            else if ((data.trim()) === "bye") {
              this.shortStringArray.push("B")
            }
            else if ((data.trim()) === "overthrow") {
              this.shortStringArray.push("OT")
            }
            else if ((data.trim()) === "shortRun") {
              this.params.ballTypeAndRun.shortRun = 1;
              this.shortStringArray.push("ST")
            }
            this.scoreStringArray.push(data.trim());
          }
          break;
        }
        case 'Ball Start': case '1 OR 2': case '2 OR 3': case '4 OR 6': case 'Hawa mei': case 'Hit/Maara': case 'Free Hit': case 'Dead Ball': case 'Bowler Stop': {
          if (this.extraButtonArray.includes(data.trim())) {
          }
          else {
            this.extraButtonArray.push(data.trim());
          }
          break;
        }
        case 'Wicket': {
         
          
          if (this.scoreStringArray.includes('wicket')) {
          }
          else {
            this.shortStringArray.push('w');
            this.scoreStringArray.push('wicket');
          }
          break;
        }
        case 'Caught': {
          this.wicketReasonArray = [];
          this.params.ballTypeAndRun.outType = "";
          if (this.wicketForm.value.bowler.length > 0) {
            let playerNames = this.BowlerAndBatsManInitials();
            this.wicketReasonArray.push('Caught');
            this.params.ballTypeAndRun.outType = "Caught";
            this.params.ballTypeAndRun.fielderName = this.wicketForm.value.bowler[0].name;
            this.params.ballTypeAndRun.wicketType = `(c) ${playerNames[1]} (b) ${playerNames[0]}`;
            this.params.ballTypeAndRun.speechWicket = `caught ${this.wicketForm.value.bowler[0].name} bowled ${this.matchDetailObject.now.bowler.name}`;
          }
          else if (this.wicketForm.value.bowler.length == 0) {
            this.resetWicketModel();
            return this.notificationService.show("Select Atleast One Fielder");
          }
          break;
        }
        case "Bowled": {
          let playerNames = this.BowlerAndBatsManInitials();
          this.wicketReasonArray = [];
          this.params.ballTypeAndRun.outType = "";
          this.wicketReasonArray.push('Bowled');
          this.params.ballTypeAndRun.wicketType = `(b) ${playerNames[0]}`;
          this.params.ballTypeAndRun.speechWicket = `bowled by ${this.matchDetailObject.now.bowler.name}`;
          break;
        }
        case "Caught & Bowled": {
          let playerNames = this.BowlerAndBatsManInitials();
          this.wicketReasonArray = [];
          this.params.ballTypeAndRun.outType = "Caught & Bowled";
          this.wicketReasonArray.push('Caught & Bowled');
          this.params.ballTypeAndRun.wicketType = `c&b ${playerNames[0]}`;
          this.params.ballTypeAndRun.speechWicket = `Caught and Bowled by ${this.matchDetailObject.now.bowler.name}`;
          break;
        }
        case "Caught Behind": {
          this.wicketReasonArray = [];
          let playerNames = this.BowlerAndBatsManInitials();
          this.params.ballTypeAndRun.outType = "";
          if (this.wicketForm.value.bowler.length > 0) {
            this.wicketReasonArray.push('Caught Behind');
            this.params.ballTypeAndRun.wicketType = `(c) ${playerNames[1]} (b) ${playerNames[0]}`;
            this.params.ballTypeAndRun.speechWicket = `caught behind by ${this.wicketForm.value.bowler[0].name} bowled by ${this.matchDetailObject.now.bowler.name}`;
          }
          else if (this.wicketForm.value.bowler.length == 0) {
            this.resetWicketModel();
            return this.notificationService.show("Select Atleast One Fielder");
          }
          break;
        }
        case "RunOut": {
          this.wicketReasonArray = [];
          if (this.wicketForm.value.bowler.length == 1) {
            let playerNames = this.BowlerAndBatsManInitials();
            this.wicketReasonArray.push('RunOut');
            this.params.ballTypeAndRun.outType = "runOut";
            this.params.ballTypeAndRun.fielderName = this.wicketForm.value.bowler[0].name;
            this.params.ballTypeAndRun.wicketType = `run out(${playerNames[1]})`;
            this.params.ballTypeAndRun.speechWicket = `run out by ${this.wicketForm.value.bowler[0].name}`;
          }
          else if (this.wicketForm.value.bowler.length >= 2) {
            let playerNames = this.BowlerAndBatsManInitials();
            this.wicketReasonArray.push('RunOut');
            this.params.ballTypeAndRun.outType = "runOut";
            this.params.ballTypeAndRun.fielderName = `${this.wicketForm.value.bowler[0].name}/${this.wicketForm.value.bowler[1].name}`;
            this.params.ballTypeAndRun.wicketType = `run out (${playerNames[1]}/${playerNames[2]})`;
            this.params.ballTypeAndRun.speechWicket = `run out by ${this.wicketForm.value.bowler[0].name} aur ${this.wicketForm.value.bowler[1].name}`;
          }
          else if (this.wicketForm.value.bowler.length == 0) {
            this.resetWicketModel();
            this.params.ballTypeAndRun.outType = "";
            return this.notificationService.show("Select Atleast One Fielder");
          }
          break;
        }
        case "LBW": {
          let playerNames = this.BowlerAndBatsManInitials();
          this.wicketReasonArray = [];
          this.params.ballTypeAndRun.outType = "LBW";
          this.wicketReasonArray.push('LBW');
          this.params.ballTypeAndRun.wicketType = `lbw ${playerNames[0]}`;
          this.params.ballTypeAndRun.speechWicket = `lbw by ${this.matchDetailObject.now.bowler.name}`;
          break;
        }
        case "Stumped": {
          this.wicketReasonArray = [];
          this.params.ballTypeAndRun.outType = "";
          if (this.wicketForm.value.bowler.length > 0) {
            let playerNames = this.BowlerAndBatsManInitials();
            this.wicketReasonArray.push('Stumped');
            this.params.ballTypeAndRun.wicketType = `st ${playerNames[1]} ${playerNames[0]}`;
            this.params.ballTypeAndRun.speechWicket = `stumped by ${this.wicketForm.value.bowler[0].name} and bowled by ${this.matchDetailObject.now.bowler.name}`;
          }
          else if (this.wicketForm.value.bowler.length == 0) {
            this.resetWicketModel();
            return this.notificationService.show("Select Atleast One Fielder");
          }
          break;
        }
        case "Mankading": {
          let playerNames = this.BowlerAndBatsManInitials();
          this.wicketReasonArray = [];
          this.params.ballTypeAndRun.outType = "";
          this.wicketReasonArray.push('Mankading');
          this.params.ballTypeAndRun.wicketType = `mankading (${playerNames[0]})`;
          this.params.ballTypeAndRun.speechWicket = `mankading by ${this.matchDetailObject.now.bowler.name}`;
          break;
        }
        case "Timed Out": {
          this.wicketReasonArray = [];
          this.params.ballTypeAndRun.outType = "";
          this.wicketReasonArray.push('Timed Out');
          this.params.ballTypeAndRun.wicketType = `timeout`;
          this.params.ballTypeAndRun.speechWicket = `timeout`;
          break;
        }
        case "Handling Ball": {
          let playerNames = this.BowlerAndBatsManInitials();
          this.wicketReasonArray = [];
          this.params.ballTypeAndRun.outType = "";
          this.wicketReasonArray.push('Handling Ball');
          this.params.ballTypeAndRun.wicketType = `ball handling ${playerNames[0]}`;
          this.params.ballTypeAndRun.speechWicket = `ball handling by ${this.matchDetailObject.now.bowler.name}`;
          break;
        }
        case "Obstructing field": {
          let playerNames = this.BowlerAndBatsManInitials();
          this.wicketReasonArray = [];
          this.params.ballTypeAndRun.outType = "";
          this.wicketReasonArray.push('Obstructing field');
          this.params.ballTypeAndRun.wicketType = `obstructing field (${playerNames[0]})`;
          this.params.ballTypeAndRun.speechWicket = `obstructing field by ${this.matchDetailObject.now.bowler.name}`;
          break;
        }
        case "Retired Hurt": {
          this.wicketReasonArray = [];
          this.params.ballTypeAndRun.outType = "";
          this.wicketReasonArray.push('Retired Hurt');
          this.params.ballTypeAndRun.wicketType = `retired hurt`;
          this.params.ballTypeAndRun.speechWicket = `retired hurt`;
          break;
        }
        case "Hitting Ball twice": {
          let playerNames = this.BowlerAndBatsManInitials();
          this.wicketReasonArray = [];
          this.params.ballTypeAndRun.outType = "";
          this.wicketReasonArray.push('Hitting Ball twice');
          this.params.ballTypeAndRun.wicketType = `hitting ball twice ${playerNames[0]}`;
          this.params.ballTypeAndRun.speechWicket = `hitting ball twice by ${this.matchDetailObject.now.bowler.name}`;
          break;
        }
        case "wicketDone": {
          if (this.wicketForm.valid) {
            if (this.wicketReasonArray.length == 0) {
              return this.notificationService.show("Select Reason For Wicket");
            }
            else if (this.wicketReasonArray.length > 0) {
              this.isWicketActive = true;
              let indexOfWicketConfirm = this.shortStringArray.indexOf('WC');
              if (!(indexOfWicketConfirm != -1)) {
                this.scoreStringArray.push("Wkt Confirm");
                this.shortStringArray.push("WC");
              }
              this.sendBatsManForWicket();
              this.isLoading = false;
            }
          }
          else {
            this.isLoading = false
            this.notificationService.show("Select Batsman")
          }
          break;
        }
        case "done": {
          // if ((!(this.matchDetailObject.now.striker.batting_style)) || (!(this.matchDetailObject.now.nonstriker.batting_style)))
          //   return this.notificationService.show("First Set LHB Or RHB of Batsman");
          let indexofAppeal = this.commentryStringArray.indexOf("Appeal");
          let indexofThirdEmpire = this.commentryStringArray.indexOf("Third Empire");
          if (indexofThirdEmpire != -1 && indexofAppeal != -1) {
            if (indexofAppeal > indexofThirdEmpire) {
              this.commentryStringArray[indexofAppeal] = "Third Empire";
              this.commentryStringArray[indexofThirdEmpire] = "Appeal";
            }
          }
          this.params.commentryStringArray = this.commentryStringArray;
          if (this.reviewStringArray.length > 0) {
            for (let index = 0; index < this.reviewStringArray.length; index++) {
              this.params.review = (this.params.review).concat(' ', this.reviewStringArray[index]);
              if (index < this.reviewStringArray.length - 1) {
                this.params.review = (this.params.review).concat(' >')
              }
            }
          }
          this.isLoading = true;
          let indexofShortNumber = this.scoreStringArray.indexOf("shortRun");
          if (indexofShortNumber != -1) {
            this.commentryStringArray.push('shortRun');
            let values = ["ST", "shortRun"];
            this.scoreStringArray = this.scoreStringArray.filter(item => !values.includes(item));
            this.shortStringArray = this.shortStringArray.filter(item => !values.includes(item));
          }
          let indexOfWicketConfirm = this.scoreStringArray.indexOf('Wkt Confirm');
          let indexOfWicket = this.scoreStringArray.indexOf('wicket');
          if (indexOfWicket != -1 && indexOfWicketConfirm === -1) {
            this.isLoading = false;
            return this.notificationService.show("Please send the detail of wicket Confirm");
          }
          if (indexOfWicket != -1 && indexOfWicketConfirm != -1) {
            let values = ["WC", "w", "Wkt Confirm", "wicket"];
            this.scoreStringArray = this.scoreStringArray.filter(item => !values.includes(item));
            this.shortStringArray = this.shortStringArray.filter(item => !values.includes(item));
          }
          if (indexOfWicketConfirm != -1) {
            let values = ["WC", "Wkt Confirm"];
            this.scoreStringArray = this.scoreStringArray.filter(item => !values.includes(item));
            this.shortStringArray = this.shortStringArray.filter(item => !values.includes(item));
          }
          if (this.params.ballTypeAndRun.wicket == 1) {
            let outBatsman = "";
            let splitOutBatsman = (this.params.outBatsman).split(' ')
            for (let index = 0; index < splitOutBatsman.length; index++) {
              if (index < splitOutBatsman.length - 1) {
                if (splitOutBatsman[index].length == 2) {
                  outBatsman = (`${outBatsman} ${splitOutBatsman[index]}`).toString().toUpperCase();
                }
                else {
                  outBatsman = (`${outBatsman}${splitOutBatsman[index].charAt(0)}`).toString().toUpperCase();
                }
              }
              else if (index == splitOutBatsman.length - 1) {
                outBatsman = (`${outBatsman} ${splitOutBatsman[splitOutBatsman.length - 1]}`).toString();
              }
            }
            this.params.ballTypeAndRun.scorecardCom = this.params.ballTypeAndRun.wicketType;
            this.params.ballTypeAndRun.wicketType = (outBatsman) + " " + (this.params.ballTypeAndRun.wicketType)
          }
          let indexOfLegBye = this.scoreStringArray.indexOf('legbye');
          let indexOfBye = this.scoreStringArray.indexOf('bye');
          let indexOfOverthrow = this.scoreStringArray.indexOf("overthrow");
          let indexOfWide = this.scoreStringArray.indexOf("wide");
          let indexOfNoBall = this.scoreStringArray.indexOf("noball");
          if (indexOfNoBall != -1 && indexOfLegBye != -1 && indexOfOverthrow != -1 && this.scoreStringArray.length == 3) {
            this.scoreStringArray.splice(indexOfNoBall, 1);
            this.params.ballTypeAndRun[this.scoreStringArray[1]] = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
            this.params.ballTypeAndRun[this.scoreStringArray[0]] = this.scoreNumberArray[this.scoreNumberArray.length - 2] || 0;
            this.params.ballTypeAndRun.noball = 0;
          }
          else if (indexOfNoBall != -1 && indexOfBye != -1 && indexOfOverthrow != -1 && this.scoreStringArray.length == 3) {
            this.scoreStringArray.splice(indexOfNoBall, 1);
            this.params.ballTypeAndRun[this.scoreStringArray[1]] = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
            this.params.ballTypeAndRun[this.scoreStringArray[0]] = this.scoreNumberArray[this.scoreNumberArray.length - 2] || 0;
            this.params.ballTypeAndRun.noball = 0;
          }
          else if (indexOfNoBall != -1 && indexOfLegBye != -1 && this.scoreStringArray.length == 2) {
            this.scoreStringArray.splice(indexOfNoBall, 1);
            this.params.ballTypeAndRun[this.scoreStringArray[0]] = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
            this.params.ballTypeAndRun.noball = 0;
          }
          else if (indexOfNoBall != -1 && indexOfBye != -1 && this.scoreStringArray.length == 2) {
            this.scoreStringArray.splice(indexOfNoBall, 1);
            this.params.ballTypeAndRun[this.scoreStringArray[0]] = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
            this.params.ballTypeAndRun.noball = 0;
          }
          else if (indexOfWide != -1 && this.scoreStringArray.length == 1) {
            this.params.ballTypeAndRun.wide = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
          }
          else if (indexOfLegBye != -1 && indexOfOverthrow != -1 && this.scoreStringArray.length == 2) {
            this.params.ballTypeAndRun[this.scoreStringArray[1]] = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
            this.params.ballTypeAndRun[this.scoreStringArray[0]] = this.scoreNumberArray[this.scoreNumberArray.length - 2] || 0;
          }
          else if (indexOfBye != -1 && indexOfOverthrow != -1 && this.scoreStringArray.length == 2) {
            this.params.ballTypeAndRun[this.scoreStringArray[1]] = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
            this.params.ballTypeAndRun[this.scoreStringArray[0]] = this.scoreNumberArray[this.scoreNumberArray.length - 2] || 0;
          }
          else if (indexOfNoBall != -1 && indexOfOverthrow != -1 && this.scoreStringArray.length == 2) {
            this.params.ballTypeAndRun[this.scoreStringArray[1]] = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
            this.params.ballTypeAndRun[this.scoreStringArray[0]] = this.scoreNumberArray[this.scoreNumberArray.length - 2] || 0;
          }
          else if (indexOfLegBye != -1 && this.scoreStringArray.length == 1) {
            this.params.ballTypeAndRun["legbye"] = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
          }
          else if (indexOfBye != -1 && this.scoreStringArray.length == 1) {
            this.params.ballTypeAndRun["bye"] = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
          }
          else if (indexOfNoBall != -1 && this.scoreStringArray.length == 1) {
            this.params.ballTypeAndRun["noball"] = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
          }
          else if (indexOfOverthrow != -1 && this.scoreStringArray.length == 1) {
            this.params.ballTypeAndRun["overthrow"] = this.scoreNumberArray[this.scoreNumberArray.length - 1];
            this.params.ballTypeAndRun["normal"] = this.scoreNumberArray[this.scoreNumberArray.length - 2] ? this.scoreNumberArray[this.scoreNumberArray.length - 2] : 0;
          }
          else if (this.scoreStringArray.length === 0) {
            if (this.scoreNumberArray.length == 0) {
              this.params['onlyDone'] = true;
            }
            this.params.ballTypeAndRun["normal"] = this.scoreNumberArray[this.scoreNumberArray.length - 1] ? this.scoreNumberArray[this.scoreNumberArray.length - 1] : 0;
          }
          else if (this.scoreStringArray.length == 1 && this.scoreStringArray[0] == 'wicket') {
            this.params.ballTypeAndRun["normal"] = this.scoreNumberArray[this.scoreNumberArray.length - 1] ? this.scoreNumberArray[this.scoreNumberArray.length - 1] : 0;
          }
          this.matchDataPassed();
          this.params.matchKey = this.matchDetailObject.matchKey;
          this.params.currentInning = Number(this.matchDetailObject.now.innings);
          this.params.currentBatsman = this.matchDetailObject.now.striker.name;
          this.params.currentTeam = this.matchDetailObject.settingObj.currentTeam;
          this.params.currentBowler = this.matchDetailObject.now.bowler.name;
          this.params.nonStrikerBatsman = this.matchDetailObject.now.nonstriker.name;
          if (true) {
            this.userService.addScore(this.params).then((responce) => {
              if (responce.statusCode == 1) {
                // responce.responseData.teams.a.currentTeam = 'a';
                // responce.responseData.teams.b.currentTeam = 'b';
                // this.matchDetailObject.teams = responce.responseData.teams;
                // this.matchDetailObject.now.req = responce.responseData.now.req;
                // this.matchDetailObject.now.balls = responce.responseData.now.balls;
                // this.changeRequestObject.showCommentry = true;
                // sessionStorage.setItem('changeRequestObject', JSON.stringify(this.changeRequestObject));
                // if (this.matchDetailObject.now.striker.name == responce.responseData.now.nonstriker.name && this.matchDetailObject.now.nonstriker.name == responce.responseData.now.striker.name) {
                //   this.matchDetailObject.now.striker = responce.responseData.now.striker;
                //   this.matchDetailObject.now.nonstriker = responce.responseData.now.nonstriker;
                //   this.matchDetailObject.now.bowler = responce.responseData.now.bowler;
                //   this.changeRequestObject.change_Bowler_Batsman = true;
                //   sessionStorage.setItem('changeRequestObject', JSON.stringify(this.changeRequestObject));
                // }
                // if (this.params.ballTypeAndRun.wicket == 1) {
                //   this.changeRequestObject.wicketFlag = true;
                //   sessionStorage.setItem('changeRequestObject', JSON.stringify(this.changeRequestObject));
                // }
                // else {
                //   this.matchDetailObject.now.striker = responce.responseData.now.striker;
                //   this.matchDetailObject.now.nonstriker = responce.responseData.now.nonstriker;
                //   this.matchDetailObject.now.bowler = responce.responseData.now.bowler;
                // }
                // this.matchDetailObject.now.lastBowler = responce.responseData.now.lastBowler;
                // this.matchDetailObject.innings_batting_order = responce.responseData.innings_batting_order;
                // this.reverseForLast24(responce.responseData.now.last24Balls);
                this.resetButtonsValueAndRequestObject();
                if (this.params.wwmute) {
                  this.openwagonWheel();
                }
                this.isLoading = false;
              } else {
                this.isLoading = false;
                this.resetButtonsValueAndRequestObject();
                this.notificationService.show('something Went wrong');
              }
            })
              .catch((err) => {
                this.isLoading = false;
              });
          }
          break;
        }
        case 'Undo': {
          this.isLoading = true;
          let obj = {
            matchKey: this.matchDetailObject.matchKey
          }
          this.userService.undoLastEntry(obj).then((responce) => {
            responce.responseData.teams.a.currentTeam = 'a';
            responce.responseData.teams.b.currentTeam = 'b';
            this.matchDetailObject.teams = responce.responseData.teams;
            this.matchDetailObject.now.req = responce.responseData.now.req;
            this.changeRequestObject.showCommentry = true;
            this.changeRequestObject.change_Bowler_Batsman = true;
            this.matchDetailObject.now.striker = responce.responseData.now.striker;
            this.matchDetailObject.now.nonstriker = responce.responseData.now.nonstriker;
            this.matchDetailObject.now.bowler = responce.responseData.now.bowler;
            sessionStorage.setItem('changeRequestObject', JSON.stringify(this.changeRequestObject));
            this.reverseForLast24(responce.responseData.now.last24Balls);
            this.resetButtonsValueAndRequestObject();
            this.isLoading = false;
          }).catch((err) => {
           
            this.isLoading = false;
          })
          this.isLoading = false;
          break;
        }
      }
    }
    else {
      switch (data) {
        case 0: case 1: case 2: case 3: case 4: case 6: {
          this.scoreNumberArray.push(data)
          break
        }
        default: {
          break
        }
      }
    }
    this.sendSocketData(data);
  }
  public reverseForLast24(obj) {
    var arr = [];
    for (var key in obj) {
      arr.push(obj[key]);
    }
    this.matchDetailObject.now['last24Balls'] = arr.slice().reverse();
  }
  sendSocketDataOfDrinks(data) {
    if (data == 'Innings' || data == 'Lunch' || data == 'Tea' || data == 'Injury') {
      this.sendSocketData(`${data}`);
      this.closeModal();
    }
    else {
      this.sendSocketData(data);
      this.closeModal();
    }
    this.breakForm.reset();
  }
  sendSocketData(data) {
    console.log('Sending socket data',this.scoreStringArray,this.commentryStringArray,this.scoreNumberArray);
    
    /****************************Logic For Highlights******************************/
    if ((data != 'done' || data != 'Undo') && data != 'wicketDone') {
      if (data == 'noball' || data == 'overthrow' || data == 'Third Empire' || data == 'Appeal' || data == 'Catch Drop' || data == 'Free Hit') {
        if (!(this.commentryStringArray.includes(data.trim()))) {
          this.commentryStringArray.push(data)
        }
      }
      if (data == 4 || data == 6) {
        if (!(this.commentryStringArray.includes(data))) {
          this.commentryStringArray.push(data)
        }
      }
    }
    /**************************** End Of Logic  For Highlights******************************/
    if (this.params.mute) {
      return "";
    }
    this.matchDataPassed();
    let commentryParams = JSON.parse(JSON.stringify(this.params));
    commentryParams.ballTypeAndRun.wideDefault = this.matchDetailObject.settingObj.wideBall;
    commentryParams.ballTypeAndRun.noBallDefault = this.matchDetailObject.settingObj.noBall;
    if (data == 'done') {
      return this.socketService.sendData({ 'key': this.matchDetailObject.matchKey, 'commentary': String(data), commentryParams });
    }
    if (data == 'Undo') {
      return;
    }
    /************************Logic for Sending Object for commentry*********************************** */
    let scoreStringArray = this.scoreStringArray.slice();
    let scoreNumberArray = this.scoreNumberArray.slice();
    let shortStringArray = this.shortStringArray.slice();
    let indexofShortNumber = scoreStringArray.indexOf("shortRun");
    if (indexofShortNumber != -1) {
      let values = ["ST", "shortRun"];
      scoreStringArray = scoreStringArray.filter(item => !values.includes(item));
      shortStringArray = shortStringArray.filter(item => !values.includes(item));
    }
    let indexOfWicketConfirm = scoreStringArray.indexOf('Wkt Confirm');
    let indexOfWicket = scoreStringArray.indexOf('wicket');
    if (indexOfWicket != -1 && indexOfWicketConfirm != -1) {
      let values = ["WC", "w", "Wkt Confirm", "wicket"];
      scoreStringArray = scoreStringArray.filter(item => !values.includes(item));
      shortStringArray = shortStringArray.filter(item => !values.includes(item));
    }
    if (indexOfWicketConfirm != -1) {
      let values = ["WC", "Wkt Confirm"];
      scoreStringArray = scoreStringArray.filter(item => !values.includes(item));
      shortStringArray = shortStringArray.filter(item => !values.includes(item));
    }
    if (commentryParams.ballTypeAndRun.wicket == 1) {
      let outBatsman = "";
      let splitOutBatsman = (commentryParams.outBatsman).split(' ')
      for (let index = 0; index < splitOutBatsman.length; index++) {
        if (index < splitOutBatsman.length - 1) {
          if (splitOutBatsman[index].length == 2) {
            outBatsman = (`${outBatsman} ${splitOutBatsman[index]}`).toString().toUpperCase();
          }
          else {
            outBatsman = (`${outBatsman}${splitOutBatsman[index].charAt(0)}`).toString().toUpperCase();
          }
        }
        else if (index == splitOutBatsman.length - 1) {
          outBatsman = (`${outBatsman} ${splitOutBatsman[splitOutBatsman.length - 1]}`).toString();
        }
      }
      commentryParams.scorecardCom = commentryParams.ballTypeAndRun.wicketType;
      commentryParams.ballTypeAndRun.wicketType = (outBatsman) + " " + (commentryParams.ballTypeAndRun.wicketType)
    }
    let indexOfLegBye = scoreStringArray.indexOf('legbye');
    let indexOfBye = scoreStringArray.indexOf('bye');
    let indexOfOverthrow = scoreStringArray.indexOf("overthrow");
    let indexOfWide = scoreStringArray.indexOf("wide");
    let indexOfNoBall = scoreStringArray.indexOf("noball");
    if (indexOfNoBall != -1 && indexOfLegBye != -1 && indexOfOverthrow != -1 && this.scoreStringArray.length == 3) {
      scoreStringArray.splice(indexOfNoBall, 1);
      commentryParams.ballTypeAndRun[scoreStringArray[1]] = scoreNumberArray[scoreNumberArray.length - 1] || 0;
      commentryParams.ballTypeAndRun[scoreStringArray[0]] = scoreNumberArray[scoreNumberArray.length - 2] || 0;
      commentryParams.ballTypeAndRun.noball = 0;
    }
    else if (indexOfNoBall != -1 && indexOfBye != -1 && indexOfOverthrow != -1 && this.scoreStringArray.length == 3) {
      scoreStringArray.splice(indexOfNoBall, 1);
      commentryParams.ballTypeAndRun[scoreStringArray[1]] = scoreNumberArray[scoreNumberArray.length - 1] || 0;
      commentryParams.ballTypeAndRun[scoreStringArray[0]] = scoreNumberArray[scoreNumberArray.length - 2] || 0;
      commentryParams.ballTypeAndRun.noball = 0;
    }
    else if (indexOfNoBall != -1 && indexOfLegBye != -1 && this.scoreStringArray.length == 2) {
      scoreStringArray.splice(indexOfNoBall, 1);
      commentryParams.ballTypeAndRun[this.scoreStringArray[0]] = this.scoreNumberArray[this.scoreNumberArray.length - 1] || 0;
      commentryParams.ballTypeAndRun.noball = 0;
    }
    else if (indexOfNoBall != -1 && indexOfBye != -1 && this.scoreStringArray.length == 2) {
      this.scoreStringArray.splice(indexOfNoBall, 1);
      commentryParams.ballTypeAndRun[scoreStringArray[0]] = scoreNumberArray[scoreNumberArray.length - 1] || 0;
      commentryParams.ballTypeAndRun.noball = 0;
    }
    else if (indexOfWide != -1 && this.scoreStringArray.length == 1) {
      commentryParams.ballTypeAndRun.wide = scoreNumberArray[scoreNumberArray.length - 1] || 0;
    }
    else if (indexOfLegBye != -1 && indexOfOverthrow != -1 && scoreStringArray.length == 2) {
      commentryParams.ballTypeAndRun[scoreStringArray[1]] = scoreNumberArray[scoreNumberArray.length - 1] || 0;
      commentryParams.ballTypeAndRun[scoreStringArray[0]] = scoreNumberArray[scoreNumberArray.length - 2] || 0;
    }
    else if (indexOfBye != -1 && indexOfOverthrow != -1 && scoreStringArray.length == 2) {
      commentryParams.ballTypeAndRun[scoreStringArray[1]] = scoreNumberArray[scoreNumberArray.length - 1] || 0;
      commentryParams.ballTypeAndRun[scoreStringArray[0]] = scoreNumberArray[scoreNumberArray.length - 2] || 0;
    }
    else if (indexOfNoBall != -1 && indexOfOverthrow != -1 && this.scoreStringArray.length == 2) {
      commentryParams.ballTypeAndRun[scoreStringArray[1]] = scoreNumberArray[scoreNumberArray.length - 1] || 0;
      commentryParams.ballTypeAndRun[scoreStringArray[0]] = scoreNumberArray[scoreNumberArray.length - 2] || 0;
    }
    else if (indexOfLegBye != -1 && this.scoreStringArray.length == 1) {
      commentryParams.ballTypeAndRun["legbye"] = scoreNumberArray[scoreNumberArray.length - 1] || 0;
    }
    else if (indexOfBye != -1 && this.scoreStringArray.length == 1) {
      commentryParams.ballTypeAndRun["bye"] = scoreNumberArray[scoreNumberArray.length - 1] || 0;
    }
    else if (indexOfNoBall != -1 && this.scoreStringArray.length == 1) {
      commentryParams.ballTypeAndRun["noball"] = scoreNumberArray[scoreNumberArray.length - 1] || 0;
    }
    else if (indexOfOverthrow != -1 && this.scoreStringArray.length == 1) {
      commentryParams.ballTypeAndRun["overthrow"] = scoreNumberArray[scoreNumberArray.length - 1];
      commentryParams.ballTypeAndRun["normal"] = this.scoreNumberArray[scoreNumberArray.length - 2] ? scoreNumberArray[scoreNumberArray.length - 2] : 0;
    }
    else if (scoreStringArray.length === 0) {
      commentryParams.ballTypeAndRun["normal"] = scoreNumberArray[scoreNumberArray.length - 1] ? scoreNumberArray[scoreNumberArray.length - 1] : 0;
    }
    else if (scoreStringArray.length == 1 && scoreStringArray[0] == 'wicket') {
      commentryParams.ballTypeAndRun["normal"] = this.scoreNumberArray[scoreNumberArray.length - 1] ? scoreNumberArray[scoreNumberArray.length - 1] : 0;
    }
    else if(indexOfWide!=-1 && indexOfWicket!=-1){
     
      commentryParams.ballTypeAndRun["wide"] = scoreNumberArray[scoreNumberArray.length - 1] || 0;
    }
    if (data == 'Caught' || data == 'Caught Behind' || data == 'LBW' || data == 'Bowled' || data == 'RunOut' || data == 'Caught & Bowled' || data == "Stumped" || data == 'Mankading' || data == 'Timed Out' || data == 'Handling Ball' || data == 'Obstructing field' || data == 'Retired Hurt' || data == 'Hitting Ball twice') {
      return;
    }
    else if (data == 'Free Hit' ||
      data == 'Wicket' ||
      data == 'Ball Start' ||
      data == '1 OR 2' ||
      data == '2 OR 3' ||
      data == '4 OR 6' ||
      data == 'Catch Drop' ||
      data == 'Review' ||
      data == 'Bowling Review' ||
      data == 'Bating Review' ||
      data == 'Out' ||
      data == 'Not Out : Review retained' ||
      data == 'Review Lost' ||
      data == 'Out : Review retained' ||
      data == 'Not Out' ||
      data == `Umpire's Call: Review Retained` ||
      data == 'Hawa mei' ||
      data == 'Hit/Maara' ||
      data == 'Dead Ball' ||
      data == 'Bowler Stop' ||
      data == 'Third Empire' ||
      data == 'Appeal') {
      this.socketService.sendData({ 'key': this.matchDetailObject.matchKey, 'commentary': String(data), commentryParams: commentryParams.ballTypeAndRun });
    }
    else if (
      data == 'Innings' ||
      data == 'Drinks' ||
      data == 'Timeout' ||
      data == 'Bad light' ||
      data == 'Tea' ||
      data == 'Rain Delay' ||
      data == 'Ground Problem' ||
      data == 'Bad Weather' ||
      data == 'Lunch' ||
      data == 'Injury' ||
      data == 'Dinner' ||
      data == 'Timeout') {
      this.socketService.sendData({ 'key': this.matchDetailObject.matchKey, 'commentary': String(data), commentryParams: commentryParams.ballTypeAndRun, 'break': true });
    }
    else {
      this.socketService.sendData({ 'key': this.matchDetailObject.matchKey, 'commentary': "", commentryParams: commentryParams.ballTypeAndRun });
    }
    /**************************************End Of Logic */
  }
  formActionAddBreak(data) {
    if (this.breakForm.valid) {
      if (this.params.mute) {
        return "";
      }
      this.matchDataPassed();
      let commentryParams = JSON.parse(JSON.stringify(this.params));
      this.socketService.sendData({ 'key': this.matchDetailObject.matchKey, 'commentary': data.name, commentryParams, isCustom: true });
      this.closeModal();
    }
    else {
      this.notificationService.show("Type Reason");
    }
  }
  matchDataPassed() {
    this.userService.matchObjSubject.subscribe((data: any) => {
      this.matchDetailObject = data;
      this.bowler = data.bowler;
      return data
    })
  }
  disableFunc(data) {
    let existValue = this.scoreNumberArray.indexOf(data)
    if (existValue != -1) {
      return false
    }
    else if (this.buttonNumberDisable && existValue == -1) {
      return true
    }
  }
  openWicket() {
    this.matchDataPassed();
    this.openModal()
  }
  openPenalty() {
    this.penaltyForm.reset();
    this.openPenaltyModal();
  }
  openwagonWheel() {
    this.resetWrangonWheelObject();
    this.openWagonWheelModal();
    this.wragonWheelObj.matchKey = this.matchDetailObject.matchKey;
    this.wragonWheelObj.currentTeam = this.matchDetailObject.settingObj.currentTeam;
    this.wragonWheelObj.currentInning = String(this.matchDetailObject.settingObj.currentInning);
    if (this.matchDetailObject.now.striker.batting_style[0] == "Left-Hand Bat") {
      this.wragonWheelObj.bowler_right_left_direction = "lhb"
      this.pieChartBrowser("Left-Hand Bat");
    }
    else {
      this.wragonWheelObj.bowler_right_left_direction = "rhb"
      this.pieChartBrowser("Right-Hand Bat");
    }
  }
  openModal() {
    this.modalService.open(this.eventDetails, { size: 'lg' });
  }
  openPenaltyModal() {
    this.teams = [];
    this.teams.push(this.matchDetailObject.teams.a);
    this.teams.push(this.matchDetailObject.teams.b);
    this.modalService.open(this.penalty, { size: 'lg' });
  }
  closeModal() {
    this.modalService.dismissAll()
  }
  openWagonWheelModal() {
    this.modalService.open(this.wagonWheel, { size: 'xl' });
  }
  openReviewModal() {
    if (this.params.mute == true) {
      return this.notificationService.show("Commentry Muted");
    }
    this.reviewSecondRow = -1;
    this.reviewThirdRow = -1;
    this.reviewStringArray = [];
    this.modalService.open(this.review, { size: 'md' });
    this.sendSocketData('Review');
  }
  openDrinkModal() {
    if (this.params.mute == true) {
      return this.notificationService.show("Commentry Muted");
    }
    this.modalService.open(this.break, { size: 'lg' });
  }
  // batsman_direction(data){
  //   this.wragonWheelObj.batsman_direction = data; 
  // }
  bowler_direction(data) {
    this.wragonWheelObj.bowler_direction = data;
  }
  resetButtonsValueAndRequestObject() {
    this.params['onlyDone'] = undefined;
    this.params.currentInning = -1
    this.params.currentBatsman = ""
    this.params.currentTeam = ""
    this.params.nonStrikerBatsman = "";
    this.params.ballTypeAndRun = {
      wicket: -1,
      wicketType: "",
      speechWicket: "",
      scorecardCom: "",
      outType: "",
      fielderName: "",
      normal: -1,
      wide: -1,
      bye: -1,
      legbye: -1,
      noball: -1,
      overthrow: -1,
      shortRun: -1
    };
    this.params.matchKey = ""
    this.params.review = "";
    this.params.outBatsman = ""
    this.params.currentBowler = "";
    this.params.mute = false;
    this.params.reviewTakenByTeam = "",
      this.params.isReviewLost = false;
    this.scoreNumberArray = [];
    this.scoreStringArray = [];
    this.shortStringArray = [];
    this.commentryStringArray = [];
    this.extraButtonArray = [];
    this.reviewStringArray = []
    this.wicketReasonArray = [];
    this.selectedItems = [];
    this.wicketForm.get('batsman').setValue(null)
  }
  sendBatsManForWicket() {
    this.params.ballTypeAndRun.wicket = 1;
    if (this.wicketForm.value.batsman == 'batsman1') {
      this.params.outBatsman = this.matchDetailObject.now.striker.name;
    }
    else {
      this.params.outBatsman = this.matchDetailObject.now.nonstriker.name
    }
    this.params.ballTypeAndRun.speechWicket = `${this.params.outBatsman} ${this.params.ballTypeAndRun.speechWicket}`;
    /********************Close Wicket Modal******************** */
    this.closeModal();
  }
  resetWicketModel() {
    let removeActiveButtons = document.querySelectorAll("#wicketButtons .active")
    for (let index = 0; index < removeActiveButtons.length; index++) {
      this.renderer.removeClass(removeActiveButtons[index], 'active');
    }
  }
  tickMarkFunc(data) {
    debugger
    if (data == 0 || data == 1 || data == 2 || data == 3 || data == 4 || data == 6) {
      this.scoreNumberArray = this.scoreNumberArray.filter(item => {
        if (item != data) {
          return String(item)
        }
      });
    }
    else if (typeof (data) == 'string') {
      let shortStringTemp = "";
      this.scoreStringArray = this.scoreStringArray.filter(item => {
        if (item != data) {
          return item
        }
      });
      this.commentryStringArray = this.commentryStringArray.filter(item => {
        if (item != data) {
          return item
        }
      });
      if ((data.trim()) === "legbye") {
        shortStringTemp = "LB"
      }
      else if ((data.trim()) === "noball") {
        shortStringTemp = "NB"
      }
      else if ((data.trim()) === "wide") {
        shortStringTemp = "wd"
      }
      else if ((data.trim()) === "bye") {
        shortStringTemp = "B"
      }
      else if ((data.trim()) === "overthrow") {
        shortStringTemp = "OT"
      }
      else if ((data.trim()) === "wicket") {
        shortStringTemp = "w"
      }
      else if ((data.trim()) === "shortRun") {
        shortStringTemp = "ST";
        this.params.ballTypeAndRun.shortRun = -1;
      }
      else if ((data.trim()) === "Wkt Confirm") {
        shortStringTemp = "WC";
        this.isWicketActive = false;
        this.wicketReasonArray = [];
        this.selectedItems = [];
        this.params.ballTypeAndRun.wicket = -1;
        this.params.ballTypeAndRun.wicketType = "";
        this.params.outBatsman = "";
        this.wicketForm.reset();
      }
      if (shortStringTemp) {
        this.shortStringArray = this.shortStringArray.filter(item => {
          if (item != shortStringTemp) {
            return item
          }
        });
      }
    }
    if (data == 'noball' || data == 'overthrow' || data == 4 || data == 6) {

    }
  }
  formActionAddEditPenalty(data) {
    data.currentTeam = this.matchDetailObject.settingObj.currentTeam;
    data.currentInning = this.matchDetailObject.settingObj.currentInning;
    if (data.isAddBatsman == "" || data.isAddBatsman == null)
      data.isAddBatsman = false;
    if (data.isAddDelivery == "" || data.isAddDelivery == null)
      data.isAddDelivery = false;
    this.isLoading = true;
    data.matchKey = this.matchDetailObject.matchKey;
    data.runs = Number(data.run)
    if (this.penaltyForm.valid) {
      this.userService.addPenalty(data).then((responce) => {
        if (responce.statusCode == 1) {
          responce.responseData.teams.a.currentTeam = 'a';
          responce.responseData.teams.b.currentTeam = 'b';
          this.matchDetailObject.teams = responce.responseData.teams;
          this.matchDetailObject.now.striker = responce.responseData.now.striker;
          this.matchDetailObject.now.nonstriker = responce.responseData.now.nonstriker;
          this.matchDetailObject.now.bowler = responce.responseData.now.bowler;
          this.matchDetailObject.now.lastBowler = responce.responseData.now.lastBowler;
          this.matchDetailObject.innings_batting_order = responce.responseData.innings_batting_order;
          this.reverseForLast24(responce.responseData.now.last24Balls);
          this.matchDetailObject.now.req = responce.responseData.now.req;
          let changeRequestCommentry = JSON.parse(sessionStorage.getItem('changeRequestObject'));
          changeRequestCommentry.showCommentry = true;
          sessionStorage.setItem('changeRequestObject', JSON.stringify(changeRequestCommentry));
          changeRequestCommentry.bowlerChange = false;
          sessionStorage.setItem('changeRequestObject', JSON.stringify(changeRequestCommentry));
          this.notificationService.show("Penalty Added", true);
          this.closeModal();
          this.isLoading = false;
        }
        else {
          this.isLoading = false;
          this.notificationService.show(responce.error.responseMessage);
        }
      }).catch((err) => {
        this.isLoading = false;
      })
    }
    else {
      this.isLoading = false;
      this.notificationService.show("Fill Required Fields");
    }
  }
  onCheckboxChange(event) {
    this.params.mute = !(this.params.mute);
  }
  onWragonCheckboxChange(event) {
    this.params.wwmute = !(this.params.wwmute);
  }
  onCheckBoxPenalty(event) {
  }
  reviewIndexShowOrHide(data) {
    let indexOfReviewBating = this.reviewStringArray.indexOf('Bating Review');
    let indexOfReviewBowling = this.reviewStringArray.indexOf('Bowling Review');
    let indexOfOut = this.reviewStringArray.indexOf('Out');
    let indexOfNotOut = this.reviewStringArray.indexOf('Not Out');
    if (data == 'Bowling Review') {
      if (indexOfReviewBating != -1) {
        this.reviewStringArray.splice(indexOfReviewBating, 1);
      }
      if (indexOfReviewBowling != -1) {
      }
      else {
        this.reviewStringArray.push('Bowling Review')
        this.reviewSecondRow = 1;
        this.sendSocketData('Bowling Review');
      }
    }
    else if (data == 'Bating Review') {
      if (indexOfReviewBowling != -1) {
        this.reviewStringArray.splice(indexOfReviewBowling, 1);
      }
      if (indexOfReviewBating != -1) {

      }
      else {
        this.reviewStringArray.push('Bating Review');
        this.reviewSecondRow = 1;
        this.sendSocketData('Bating Review');
      }
    }
    else if (data == 'Out') {
      if (indexOfOut != -1) {
      }
      else {
        if (indexOfReviewBowling != -1) {
          this.sendSocketData('Out : Review retained');
          this.reviewStringArray.push('Out: Review retained');
          this.closeModal();
        }
        else {
          this.reviewStringArray.push('Out');
          this.sendSocketData('Out');
          this.reviewThirdRow = 2;
        }
      }
    }
    else if (data == 'Not Out') {
      if (indexOfNotOut != -1) {
      }
      else {
        if (indexOfReviewBating != -1) {
          this.sendSocketData('Not Out : Review retained');
          this.reviewStringArray.push('Not Out : Review retained');
          this.closeModal();
        }
        else {
          this.reviewThirdRow = 2;
          this.reviewStringArray.push('Not Out');
          this.sendSocketData('Not Out');
        }
      }
    }
    else if (data == 'Review Lost') {
      this.sendSocketData('Review Lost');
      this.reviewStringArray.push('Review Lost');
      if (indexOfReviewBowling != -1) {
        this.params.reviewTakenByTeam = this.matchDetailObject.settingObj.currentTeam == 'a' ? 'b' : 'a';
        this.params.isReviewLost = true;
      }
      else {
        this.params.reviewTakenByTeam = this.matchDetailObject.settingObj.currentTeam;
        this.params.isReviewLost = true;
      }
      this.closeModal();
    }
    else if (data == 'Umpires Call') {
      this.sendSocketData("Umpire's Call: Review Retained");
      this.reviewStringArray.push(`Umpire's Call: Review Retained`);
      this.closeModal();
    }
  }
}