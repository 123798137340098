import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoaderComponent } from './loader/loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorMsgPipe, CharLimitPipe, AlphabetsPipe, AdminPermissionPipe,CapitalizedPipe } from '../pipes';
import { CustomeDatePipe } from '../pipes/custome-date.pipe';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LeftSideBarComponent } from './left-side-bar/left-side-bar.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import {saveAs} from 'file-saver/dist/FileSaver';
import { ButtonsComponent } from './buttons/buttons.component';
import { CommentryPreviewComponent } from './commentry-preview/commentry-preview.component';
import { ActiveButtonDirecDirective } from './active-button-direc.directive'
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgOtpInputModule } from  'ng-otp-input';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { DialogComponent } from './dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import { PasswordDilalogComponent } from './password-dilalog/password-dilalog.component';
@NgModule({
  declarations: [
    LoaderComponent,
    ErrorMsgPipe,
    CharLimitPipe,
    AlphabetsPipe,
    CustomeDatePipe,
    AdminPermissionPipe,
    CapitalizedPipe,
    HeaderComponent,
    FooterComponent,
    LeftSideBarComponent,
    ButtonsComponent,
    CommentryPreviewComponent,
    ActiveButtonDirecDirective,
    ConfirmationDialogComponent,
    DialogComponent,
    PasswordDilalogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    AngularEditorModule,
    NgxDropzoneModule,
    NgOtpInputModule,
    AngularEditorModule,
    MatDialogModule
  ],
  entryComponents: [
    DialogComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LeftSideBarComponent,
    ButtonsComponent,
    CommentryPreviewComponent,
    LoaderComponent,
    
    ErrorMsgPipe,
    CharLimitPipe,
    AlphabetsPipe,
    AdminPermissionPipe,
    CapitalizedPipe,
    CustomeDatePipe,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    BsDatepickerModule,
    DialogComponent
  ],
  providers: [ErrorMsgPipe, CharLimitPipe, CustomeDatePipe,AdminPermissionPipe,ConfirmationDialogService],
})
export class ShareModule { }
