import { Injectable } from '@angular/core';
import { NetworkHelper } from './../share/utilities/network-helper';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, RequestOptions, Headers } from '@angular/http';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public notify401Error: Subject<any> = new Subject();
  public API_ENDPOINT = environment.apiUrl + '/api/v1/';
  public AdminLoginParam = {
    email: '',
    password: '',
  };
  public matchObjSubject = new BehaviorSubject({})
  constructor(protected httpCLient: HttpClient, private http: Http) { }

  public handle401Error(status) {
    this.notify401Error.next('401');
  }
  public login(data: any) {
    data.deviceToken = "gdhgfh",
      data.deviceId = "hfhh",
      data.deviceTypeId = "4"
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'admin/login', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ////////
  public adminDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'admin/detail', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ////////
  public profileDetail(data: any) {
    data.deviceToken = "gdhgfh",
      data.deviceId = "hfhh",
      data.deviceTypeId = "4"
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'user/profile/detail', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ////////
  public updateProfile(data) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'Authorization',
         localStorage.getItem('crickAdmin')
      );
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'user/profile/update', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ////////
  public dashboardData() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'admin/dashboard')
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public forgotPassword(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'admin/forgot/password', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ////////
  public resetPassword(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'admin/otp/verify', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ////////


  public allUsers(data: any) {
   

    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'user/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&gender=' +
          (data.gender === undefined || data.gender === null
            ? ''
            : data.gender)
          +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public allUsersReport(data: any) {
    

    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'user/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&dateKey=' +
          (data.dateKey === undefined || data.dateKey === null
            ? ''
            : data.dateKey)
          +
          '&subscription=' +
          (data.subscription === undefined || data.subscription === null
            ? ''
            : data.subscription)
          +
          
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public allUsersDateReport(data: any) {
    

    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'user/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&dateKey=' +
          (data.dateKey === undefined || data.dateKey === null
            ? ''
            : data.dateKey)
          +
          
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public UnRegisterUsersDateReport(data: any) {
    

    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'guestUser/userList?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&dateKey=' +
          (data.dateKey === undefined || data.dateKey === null
            ? ''
            : data.dateKey)
          +
          
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public updateUserStatus(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'user/profile/update/by_admin', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ////////
  public userProfile(data: any) {
   

    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'user/profile?userId=' +
          data.userId
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public allMatches(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'match/admin-matchList?pageNo=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' + 
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order)  +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
            '&matchStatus=' +
          (data.matchStatus === undefined || data.matchStatus === null
            ? ''
            : data.matchStatus) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public matchStatus(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .patch(this.API_ENDPOINT + 'match/update-match-status', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public matchDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'match/detail?matchKey=' +
          (data.matchKey)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public pollUpcommingMatch(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'poll/createPoll', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public addQuiz(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'quiz/add', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public updateQuizDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'quiz/detail/update', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public allQuizes(data: any) {
    

    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'quiz/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  // public updateQuiz(data: any) {
  //   return new Promise<any>((resolve, reject) => {
  //     this.httpCLient
  //       .post(this.API_ENDPOINT + 'quiz/update/by_admin', JSON.stringify(data))
  //       .toPromise()
  //       .then((value) => {
  //         resolve(value);
  //       })
  //       .catch((errorResponce) => {
  //         let responce: any = '';
  //         const validationErrorList = NetworkHelper.resolveErrorList(
  //           errorResponce
  //         );
  //         if (validationErrorList) {
  //           responce += validationErrorList;
  //         }
  //         reject(responce);
  //       });
  //   });
  // } 
  
  
public updateQuiz(data: any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
       localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
    // headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
    this.http
      .post(this.API_ENDPOINT + 'quiz/update/by_admin', data, options)
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
} 
  ////////
  public quizeDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'quiz/detail/by_admin?quizId=' +
          (data.quizId === undefined ? 1 : data.quizId)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public addQuizQuestion(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'quiz-question/add', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public allQuizesQuestions(data: any) {
    

    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'quiz-question/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
          +
          '&quizId=' +
          (data.quizId === undefined || data.quizId === null || data.quizId === ""
            ? ''
            : data.quizId)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public quizeQuestionDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'quiz-question/detail/by_admin?questionId=' +
          (data.questionId === undefined ? 1 : data.questionId)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public updateQuizQuestion(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'quiz-question/update/by_admin', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ////////\
  public updateQuizQuestionDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'quiz-question/update/by_admin', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public addPoll(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'poll/createPoll', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }
  public dropDownMatches(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'match/dropdown-list-admin'
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public allPolls(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'poll/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public addVenue(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'venue/add', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }
  public allVenues(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'venue/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&year=' +
          (data.year === undefined || data.year === null
            ? ''
            : data.year) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public thirdPartyVenues(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'venueScraping/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=100')
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public venueDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'venue/detail/by_admin?venueId=' +
          (data.venueId === undefined ? 1 : data.venueId)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public updateVenue(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'venue/update/by_admin', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ////////
  public updateVenueDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'venue/detail/update', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public allVenueName(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'match/getallvenues'
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public addAdds(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
        localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'ads/add', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }
  public allAds(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'ads/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public adsDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'ads/detail/by_admin?adsId=' +
          (data.adsId === undefined ? 1 : data.adsId)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public updateAddsDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'ads/detail/update', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////


  public updateAds(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'ads/update/by_admin', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }
  // public updateAds(data: any) {
  //   return new Promise<any>((resolve, reject) => {
  //     this.httpCLient
  //       .post(this.API_ENDPOINT + 'ads/update/by_admin', JSON.stringify(data))
  //       .toPromise()
  //       .then((value) => {
  //         resolve(value);
  //       })
  //       .catch((errorResponce) => {
  //         let responce: any = '';
  //         const validationErrorList = NetworkHelper.resolveErrorList(
  //           errorResponce
  //         );
  //         if (validationErrorList) {
  //           responce += validationErrorList;
  //         }
  //         reject(responce);
  //       });
  //   });
  // } ////////
  public downloadUserReport() {
    return this.httpCLient.get(this.API_ENDPOINT + 'user/download/by_admin', { responseType: 'blob' })
  } ///////
  public addRole(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'role/add', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ////////
  public updateRole(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'role/update/by_admin', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ////////
  public allRoles(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'role/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public roleDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'role/detail/by_admin?roleId=' +
          (data.roleId === undefined ? 1 : data.roleId)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public dropDownRoles(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'role/dropdown-list-admin'
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public addSubAdmin(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'admin/add-sub-admin', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }
  public updateSubAdmin(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'admin/update', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }
  public allSubAdmin(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'admin/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public subAdminDetail(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'admin/detail', JSON.stringify(data)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public updateSetting(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/updatesetting', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 
  //////////
  public updateUmpireInfo(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/updateUmpireInfo', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }   
  ///////
  public updateRequiredBalls(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/updateReqRunsAndBalls', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public updatesessionAndOverLeft(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/updateSessionAndOverLeft', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public setBatsMan(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/setBatsmanAndBowler', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public updateMatchEffect(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/addMatchOddsInMatch', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public updateMatchDraw(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/declareAndDrawUpdate', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public updateMatchFollonAndResult(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/updateFollonAndMatchResult', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///
  public addWragonWheel(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/updateWagonWheel', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public addScore(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/liveMatchEntry', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public undoLastEntry(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/undoLastEntry', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public commentry(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'admin/commentry?matchKey=' +
          data.matchKey + '&currentInning=' + data.currentInning + '&currentTeam=' + data.currentTeam + '&over_str=' + data.over_str
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  } ///////
  public updateCommentry(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/updateMatchCommentary', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public addPenalty(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/addPenaltyRuns', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public updateAddNewBall(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/addNewBall', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public deleteBall(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/deleteBallAndCommentary', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } //////
  public changeStrickerNonStricker(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/setStickerAndNoStricker', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public changeMatchStatus(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/matchStartOrCompleted', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public clearData(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'match/clearMatchData', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public setPlayerStyle(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'player/updatePlayerStyle', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////
  public pointSystemData(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'compPointTable/getPoints'
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }/////////////////////
  public pointSystemDataUpdate(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'compPointTable/updatePoints', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }/////////////////////
  public allSeries(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'series/list?page=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=100'
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public allMatchesListBySeries(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'series/matchList?pageNo=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=100' + '&key=' + (data.key)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public addCompetitionMatch(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'competition/createMatch', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }/////////////////////
  public addCompetition(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'competition/createCompetition', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }//////////////////
  public updateCompetition(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'competition/update', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }//////////////////
  public deleteCompetition(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'competition/deleteCompetitionOrMatch', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }//////////////////
  public allCompetitions(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'competition/getcom?pageNo=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public allCompetitionsOfMatch(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'competition/competitionList?pageNo=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10' +
          '&matchCompId=' +
          (data.matchCompId === undefined || data.matchCompId === null
            ? ''
            : data.matchCompId) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search)
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public allPlayers(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'player/list?pageNo=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10'
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public playerIsAppStatus(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'player/updatePlayerShowOnAppOrNot', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 

  // get About us api data 
  public getAboutUsData() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'dynamicPages/getPages')
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // Add about us data 
  public addAboutUsData(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'dynamicPages/add', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 

  public newsScrapping() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'cronjob/newsScraping', null)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 

  public vanueScrapping() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'cronjob/vanueDetailScrap', null)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 

  public scrapScrapping() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'cronjob/scrap-series',null)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 

  public teamStatsScrapping() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'cronjob/teamStats-scrap', null)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 

  public teamScrapping() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'cronjob/teams-scrap', null)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 

  public teamPlayerScrapping() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'cronjob/teamPlayer-scrap', null)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 

  public PlayerrankScrapping() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'cronjob/players-rank',null)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 

  public rankScrapping() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'cronjob/team-rank', null)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 

  public playerDetailsScrapping() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'playerDetails-scrap', null)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 


  

  //Add Faq 
  public addFaq(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'faqs/addFaqs', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }

  //Add Faq 
  public EditFaq(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'faqs/editFaqs', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }

  // get faq all data 
  public getFaqAllData(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'faqs/getFaqs?pageNo=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10'
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


    // get feedback all data 
    public getFeedbackData(data: any) {
      return new Promise<any>((resolve, reject) => {
        this.httpCLient
          .get(
            this.API_ENDPOINT +
            'feedback/getFeedback?pageNo=' +
            (data.page === undefined ? 1 : data.page) +
            '&limit=10'
          )
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }

     // get feedback with imp key all data 
     public getFeedbackDataWithImp(data: any) {
      return new Promise<any>((resolve, reject) => {
        this.httpCLient
          .get(
            this.API_ENDPOINT +
            'feedback/getFeedback?pageNo=' +
            (data.page === undefined ? 1 : data.page) +
            '&limit=10'+'&imp=true'
          )
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }




  public updateAboutUs(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'dynamicPages/update', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }



  // delete faq


  public deleteFaq(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'faqs/deleteFaq',JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }  


  // Add feature

  public addFeature(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'features/add', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }


  // get features List 
   
    public getFeaturesList(data: any) {
      return new Promise<any>((resolve, reject) => {
        this.httpCLient
          .get(
            this.API_ENDPOINT +
            'features/getFeaturesList?pageNo=' +
            (data.page === undefined ? 1 : data.page) +
            '&limit=10'
          )
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }



    // get single feature data
    public getSingleFeature(data: any) {
      return new Promise<any>((resolve, reject) => {
        this.httpCLient
          .get(
            this.API_ENDPOINT +
            'features/getSingleFeature?featureId=' +data
           
          )
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }



    // update subscription 
    public updateSubscription(data: any) {
      return new Promise<any>((resolve, reject) => {
        this.httpCLient
          .post(this.API_ENDPOINT + 'features/updateFeatureList', JSON.stringify(data))
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((errorResponce) => {
            let responce: any = '';
            const validationErrorList = NetworkHelper.resolveErrorList(
              errorResponce
            );
            if (validationErrorList) {
              responce += validationErrorList;
            }
            reject(responce);
          });
      });
    }
  


      //Add Plans 
  
  public addPlan(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'plans/add', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } ///////

  public updatePlan(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
        localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'plans/updatePlan', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  } 

    // get plans 
    public getPlans(data: any) {
      return new Promise<any>((resolve, reject) => {
        this.httpCLient
          .get(
            this.API_ENDPOINT +
            'plans/getPlans?pageNo=' +
            (data.page === undefined ? 1 : data.page) +
            '&limit=10'
          )
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }


    

         // get single plans 
         public getSinglePlan(data: any) {
          return new Promise<any>((resolve, reject) => {
            this.httpCLient
              .get(
                this.API_ENDPOINT +
                'plans/getSinglePlan?planId=' +data
               
              )
              .toPromise()
              .then((value) => {
                resolve(value);
              })
              .catch((err) => {
                reject(err);
              });
          });
        }


// get custom notification
        // public getCustomNotification(data: any) {
        //   return new Promise<any>((resolve, reject) => {
            
        //     this.httpCLient
        //       .get(
        //         this.API_ENDPOINT +
        //         'customNotification/getCustomNotificationForAdmin?pageNo=' +
        //         (data.page === undefined ? 1 : data.page) +
        //         '&limit=10'
        //       )
        //       .toPromise()
        //       .then((value) => {
        //         resolve(value);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // }



        public getCustomNotification(data:any) {
          return new Promise<any>((resolve, reject) => {
            let headers = new Headers();
            headers.append('Accept', 'application/json');
            headers.append(
              'accessToken',
              localStorage.getItem('crickAdmin'),
            );
            headers.append(
              'Authorization',
              'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
            );
           //  headers.append('Content-Type','multipart/form-data')
            let options = new RequestOptions({ headers: headers });
           
            this.http
              // .get(this.API_ENDPOINT + 'admin/subscriptionReport?subscription='+data.subscription +
              // '?pageNo=' +
              // (data.page === undefined ? 1 : data.page) +
              // '&limit=10',  options)
              .get(
                this.API_ENDPOINT +
                'customNotification/getCustomNotificationForAdmin?pageNo=' +
                (data.page === undefined ? 1 : data.page) +
                '&limit=10' , options
              )
              .toPromise()
              .then((value) => {
                resolve(value);
              })
              .catch((errorResponce) => {
                let responce: any = '';
                const validationErrorList = NetworkHelper.resolveErrorList(
                  errorResponce
                );
                if (validationErrorList) {
                  responce += validationErrorList;
                }
                reject(responce);
              });
          });
        }


      // Add custom notification

      public addCustomNotification(data: any) {
        return new Promise<any>((resolve, reject) => {
          let headers = new Headers();
          headers.append('Accept', 'application/json');
          headers.append(
            'accessToken',
            localStorage.getItem('crickAdmin'),
          );
          headers.append(
            'Authorization',
            'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
          );
          // headers.append('Content-Type','multipart/form-data')
          let options = new RequestOptions({ headers: headers });
          this.http
            .post(this.API_ENDPOINT + 'notification/sendCustomNotification', data, options)
            .toPromise()
            .then((value) => {
              resolve(value);
            })
            .catch((errorResponce) => {
              let responce: any = '';
              const validationErrorList = NetworkHelper.resolveErrorList(
                errorResponce
              );
              if (validationErrorList) {
                responce += validationErrorList;
              }
              reject(responce);
            });
        });
      }
     

     // get singleNotification 
     public getSingleNotification(data: any) {
      return new Promise<any>((resolve, reject) => {
        this.httpCLient
          .get(
            this.API_ENDPOINT +
            'notification/getUserNotification?userId=' +data
           
          )
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } 



    // update feedBack 
    public updateFeedback(data: any) {
      return new Promise<any>((resolve, reject) => {
        this.httpCLient
          .post(this.API_ENDPOINT + 'feedback/updateFeedBack', JSON.stringify(data))
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((errorResponce) => {
            let responce: any = '';
            const validationErrorList = NetworkHelper.resolveErrorList(
              errorResponce
            );
            if (validationErrorList) {
              responce += validationErrorList;
            }
            reject(responce);
          });
      });
    }


    // delete custom Notification 

    public deleteCustomNotification(data: any) {
      return new Promise<any>((resolve, reject) => {
        this.httpCLient
          .post(this.API_ENDPOINT + 'customNotification/deleteNotification', JSON.stringify(data))
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((errorResponce) => {
            let responce: any = '';
            const validationErrorList = NetworkHelper.resolveErrorList(
              errorResponce
            );
            if (validationErrorList) {
              responce += validationErrorList;
            }
            reject(responce);
          });
      });
    }


// get Advertise
public getAdvertise(data: any) {
  return new Promise<any>((resolve, reject) => {
    this.httpCLient
      .get(
        this.API_ENDPOINT +
        'advertisement/getAdvertisement?pageNo=' +
        (data.page === undefined ? 1 : data.page) +
        '&limit=10' +
         '&order=' +
        (data.order === undefined || data.order === null
          ? ''
          : data.order) +
        '&from_date=' +
        (data.from_date === undefined || data.from_date === null
          ? ''
          : data.from_date) +
        '&to_date=' +
        (data.to_date === undefined || data.to_date === null
          ? ''
          : data.to_date) +
        '&search=' +
        (data.search === undefined || data.search === null
          ? ''
          : data.search) +
        '&sortFieldName=' +
        (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
          ? ''
          : data.sortFieldName)
      )
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

  // update Advertise 
  public updateAdvertise(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'advertisement/updateAdvertisement', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }


   // get Advertise with imp key all data 
   public getAdvertiseDataWithImp(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'advertisement/getAdvertisement?pageNo=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10'+'&imp=true'
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  // Add  Reward Point

  public addRewardPoint(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'rewardPoints/add', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }


    // get RewardPoint Data 
    public getRewardPointData() {
      return new Promise<any>((resolve, reject) => {
        this.httpCLient
          .get(
            this.API_ENDPOINT +
            'rewardPoints/getRewardPoints'
           
          )
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } 

    // update Advertise 
  public updateRewardPoint(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .post(this.API_ENDPOINT + 'rewardPoints/updateRewardPoint', JSON.stringify(data))
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }


   // get Advertise with imp key all data 
   public getmatchReportData(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'match/pagination-matchList?pageNo=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=10'+'&matchStatus='+data.matchStatus + '&matchFormat='+data.matchFormat
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }



   // Add  Reward Point

   public addAdminLog(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'adminLogs/add', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }


  public checkPassword(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'admin/checkPassword', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }


  public changePassword(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
         localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'admin/changePassword', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }


 


 public getSubscriptionReports(data:any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
      localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
   //  headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
   
    this.http
      // .get(this.API_ENDPOINT + 'admin/subscriptionReport?subscription='+data.subscription +
      // '?pageNo=' +
      // (data.page === undefined ? 1 : data.page) +
      // '&limit=10',  options)
      .get(
        this.API_ENDPOINT +
        'admin/subscriptionReport?subscription='+data.subscription+'?pageNo=' +
        (data.page === undefined ? 1 : data.page) +
        '&limit=10' +
        '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
            +
        data.search , options
      )
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
}

public SendSubscriptionReports(data:any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
      localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
   //  headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
   
    this.http
      // .get(this.API_ENDPOINT + 'admin/subscriptionReport?subscription='+data.subscription +
      // '?pageNo=' +
      // (data.page === undefined ? 1 : data.page) +
      // '&limit=10',  options)
      .get(
        this.API_ENDPOINT +
        'admin/sendNotificationsubscription?subscription='+data.subscription+'?pageNo=' +
        (data.page === undefined ? 1 : data.page) +
        '&limit=10' +
        '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
            '&title=' +
            (data.title === undefined || data.title === null
              ? ''
              : data.title) +
            '&link=' +
            (data.link === undefined || data.link === null
              ? ''
              : data.link) +
            '&description=' +
            (data.description === undefined || data.description === null
              ? ''
              : data.description) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
            +
        data.search , options
      )
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
}


public updateAdminPassword(data: any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
      localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
    // headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
    this.http
      .post(this.API_ENDPOINT + 'admin/edit-sub-admin', data, options)
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
}

public updateAdminDetails(data: any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
      localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
    // headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
    this.http
      .post(this.API_ENDPOINT + 'admin/edit-sub-admin', data, options)
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
}


public getUserLogReports(data) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
      localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
   //  headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
    this.http
      .get(this.API_ENDPOINT + 'adminLogs/list?pageNo=' +
      (data.page === undefined ? 1 : data.page) +
      '&limit=20' +
       '&order=' +
      (data.order === undefined || data.order === null
        ? ''
        : data.order) +
      '&from_date=' +
      (data.from_date === undefined || data.from_date === null
        ? ''
        : data.from_date) +
      '&to_date=' +
      (data.to_date === undefined || data.to_date === null
        ? ''
        : data.to_date) +
      '&search=' +
      (data.search === undefined || data.search === null
        ? ''
        : data.search) +
      '&sortFieldName=' +
      (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
        ? ''
        : data.sortFieldName),  options)
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
}


// 
public updateFeatureList(data: any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
      localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
    // headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
    this.http
      .post(this.API_ENDPOINT + 'features/updateFeatureList', data, options)
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
}


  // get ads listing with imp key all data 
  public getAdslisting(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(
          this.API_ENDPOINT +
          'ads/list?pageNo=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=1000'
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  public disableAndenableLocation(data: any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
        localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
      // headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(this.API_ENDPOINT + 'features/disableFeature', data, options)
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }
   

  public getRewardPointReports(data:any) {
    return new Promise<any>((resolve, reject) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append(
        'accessToken',
        localStorage.getItem('crickAdmin'),
      );
      headers.append(
        'Authorization',
        'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
      );
     //  headers.append('Content-Type','multipart/form-data')
      let options = new RequestOptions({ headers: headers });
     
      this.http
        // .get(this.API_ENDPOINT + 'admin/subscriptionReport?subscription='+data.subscription +
        // '?pageNo=' +
        // (data.page === undefined ? 1 : data.page) +
        // '&limit=10',  options)
        .get(
          this.API_ENDPOINT +
          'admin/rewardReport?pageNo?pageNo=' +
          (data.page === undefined ? 1 : data.page) +
          '&limit=30'+  '&search=' +
          data.search , options
        )
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((errorResponce) => {
          let responce: any = '';
          const validationErrorList = NetworkHelper.resolveErrorList(
            errorResponce
          );
          if (validationErrorList) {
            responce += validationErrorList;
          }
          reject(responce);
        });
    });
  }


  // match download report 
 
     public allMatchesReport(data: any) {
      return new Promise<any>((resolve, reject) => {
        this.httpCLient
          .get(
            this.API_ENDPOINT +
            'match/downloadMatchReport?order=' +
            (data.order === undefined || data.order === null
              ? ''
              : data.order)  +
            '&from_date=' +
            (data.from_date === undefined || data.from_date === null
              ? ''
              : data.from_date) +
            '&to_date=' +
            (data.to_date === undefined || data.to_date === null
              ? ''
              : data.to_date) +
              '&matchStatus=' +
            (data.matchStatus === undefined || data.matchStatus === null
              ? ''
              : data.matchStatus) +
            '&search=' +
            (data.search === undefined || data.search === null
              ? ''
              : data.search) +
            '&sortFieldName=' +
            (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
              ? ''
              : data.sortFieldName)
          )
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }


    // userLog report download report 
 
    public allUserLogReport(data) {
      return new Promise<any>((resolve, reject) => {
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append(
          'accessToken',
          localStorage.getItem('crickAdmin'),
        );
        headers.append(
          'Authorization',
          'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
        );
       //  headers.append('Content-Type','multipart/form-data')
        let options = new RequestOptions({ headers: headers });
        this.http
          .get(this.API_ENDPOINT + 'adminLogs/downloadReport?order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName),  options)
          .toPromise()
          .then((value) => {
            resolve(value);
          })
          .catch((errorResponce) => {
            let responce: any = '';
            const validationErrorList = NetworkHelper.resolveErrorList(
              errorResponce
            );
            if (validationErrorList) {
              responce += validationErrorList;
            }
            reject(responce);
          });
      });
    }


    



// subscription all report

public allSubscriptionReport(data:any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
      localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
   //  headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
   
    this.http
      // .get(this.API_ENDPOINT + 'admin/subscriptionReport?subscription='+data.subscription +
      // '?pageNo=' +
      // (data.page === undefined ? 1 : data.page) +
      // '&limit=10',  options)
      .get(
        this.API_ENDPOINT +
        'admin/subscriptionReportDownload?pageNo=' +
        (data.page === undefined ? 1 : data.page) +
        '&limit=10' +
        '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&subscription=' +
          (data.subscription === undefined || data.subscription === null
            ? ''
            : data.subscription) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
            +
        data.search , options
      )
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
}


public allRewardPointReports(data:any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
      localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
   //  headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
   
    this.http
      .get(
        this.API_ENDPOINT +
        'admin/downloadReport' +
        data.search , options
      )
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });



 
}

public allUsersDownloadReport(data: any) {
    

  return new Promise<any>((resolve, reject) => {
    this.httpCLient
      .get(
        this.API_ENDPOINT +
        'user/downloadReport?' +
        '&order=' +
        (data.order === undefined || data.order === null
          ? ''
          : data.order) +
        '&from_date=' +
        (data.from_date === undefined || data.from_date === null
          ? ''
          : data.from_date) +
        '&to_date=' +
        (data.to_date === undefined || data.to_date === null
          ? ''
          : data.to_date) +
        '&search=' +
        (data.search === undefined || data.search === null
          ? ''
          : data.search) +
        '&dateKey=' +
        (data.dateKey === undefined || data.dateKey === null
          ? ''
          : data.dateKey)
        +
        '&subscription=' +
        (data.subscription === undefined || data.subscription === null
          ? ''
          : data.subscription)
        +
        
        '&sortFieldName=' +
        (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
          ? ''
          : data.sortFieldName)
      )
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((err) => {
        reject(err);
      });
  });
}



// Send Notification

 public subscriptionSendNotification(data:any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
      localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
   //  headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
   
    this.http
     
      .get(
        this.API_ENDPOINT +
        'admin/subscriptionReport?subscription=3?pageNo=' +
        (data.page === undefined ? 1 : data.page) +
        '&limit=10' +
        '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
            +
          '&description=' +
          (data.description === undefined || data.description === null || data.description === ""
            ? ''
            : data.description)
            +
            '&title=' +
            (data.title === undefined || data.title === null || data.title === ""
              ? ''
              : data.title)
              +
              '&link=' +
              (data.link === undefined || data.link === null || data.link === ""
                ? ''
                : data.link)
            +
        data.search , options
      )
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
}



      // Add custom notification

      public addUserview(data: any) {
        return new Promise<any>((resolve, reject) => {
          let headers = new Headers();
          headers.append('Accept', 'application/json');
          headers.append(
            'accessToken',
             localStorage.getItem('crickAdmin'),
          );
          headers.append(
            'Authorization',
            'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
          );
          // headers.append('Content-Type','multipart/form-data')
          let options = new RequestOptions({ headers: headers });
          this.http
            .post(this.API_ENDPOINT + 'user/addExtraPoint', data, options)
            .toPromise()
            .then((value) => {
              resolve(value);
            })
            .catch((errorResponce) => {
              let responce: any = '';
              const validationErrorList = NetworkHelper.resolveErrorList(
                errorResponce
              );
              if (validationErrorList) {
                responce += validationErrorList;
              }
              reject(responce);
            });
        });
      }



      
public getListData(data:any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
      localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
   //  headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
   
    this.http
      // .get(this.API_ENDPOINT + 'admin/subscriptionReport?subscription='+data.subscription +
      // '?pageNo=' +
      // (data.page === undefined ? 1 : data.page) +
      // '&limit=10',  options)
      .get(
        this.API_ENDPOINT +
        'user/getExtraPoint?userId='+data.userId
        +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
            +
        data.search , options
      )
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
}


public addMemberAndGift(data: any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
       localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
    // headers.append('Content-Type','multipart/form-data')
    let options = new RequestOptions({ headers: headers });
    this.http
      .post(this.API_ENDPOINT + 'admin/addMemberAndGiftSub', data, options)
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
} 


public getGiftMember(data:any) {
  return new Promise<any>((resolve, reject) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append(
      'accessToken',
      localStorage.getItem('crickAdmin'),
    );
    headers.append(
      'Authorization',
      'Basic ' + btoa("cricketRadio" + ":" + "cricket@$%#Radio123")
    );
   
    let options = new RequestOptions({ headers: headers });
   
    this.http
     
      .get(
        this.API_ENDPOINT +
        'admin/getMemberAndGiftSub?pageNo=' +
        (data.page === undefined ? 1 : data.page) +
        '&limit=10' +
        '&order=' +
          (data.order === undefined || data.order === null
            ? ''
            : data.order) +
          '&from_date=' +
          (data.from_date === undefined || data.from_date === null
            ? ''
            : data.from_date) +
          '&to_date=' +
          (data.to_date === undefined || data.to_date === null
            ? ''
            : data.to_date) +
          '&search=' +
          (data.search === undefined || data.search === null
            ? ''
            : data.search) +
          '&sortFieldName=' +
          (data.sortFieldName === undefined || data.sortFieldName === null || data.sortFieldName === ""
            ? ''
            : data.sortFieldName)
            +
        data.search , options
      )
      .toPromise()
      .then((value) => {
        resolve(value);
      })
      .catch((errorResponce) => {
        let responce: any = '';
        const validationErrorList = NetworkHelper.resolveErrorList(
          errorResponce
        );
        if (validationErrorList) {
          responce += validationErrorList;
        }
        reject(responce);
      });
  });
}




}


