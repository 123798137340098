import { Injectable } from '@angular/core';
import { NetworkHelper } from './../share/utilities/network-helper';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Http, RequestOptions, Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  public API_ENDPOINT = environment.apiUrl + '/api/';
  public nVer: any;
  public nAgt: any;
  public browserName: any;
  public fullVersion: any;
  public majorVersion: any;
  public nameOffset: any;
  public verOffset: any;
  public ix: any;
  constructor(
    protected httpCLient: HttpClient,
    private http: Http
  ) {
    this.nVer = navigator.appVersion;
    this.nAgt = navigator.userAgent;
    this.browserName = navigator.appName;
    this.fullVersion = '' + parseFloat(navigator.appVersion);
    this.majorVersion = parseInt(navigator.appVersion, 10);
  }

  public dashboard() {
    return new Promise<any>((resolve, reject) => {
      this.httpCLient
        .get(this.API_ENDPOINT + 'admin/home')
        .toPromise()
        .then((value) => {
          resolve(value);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public DeviceDetector() {
    // In Opera, the true version is after "Opera" or after "Version"
    // tslint:disable-next-line: no-conditional-assignment
    if ((this.verOffset = this.nAgt.indexOf('Opera')) != -1) {
      this.browserName = 'Opera';
      this.fullVersion = this.nAgt.substring(this.verOffset + 6);
      // tslint:disable-next-line: no-conditional-assignment
      if ((this.verOffset = this.nAgt.indexOf('Version')) != -1) {
        this.fullVersion = this.nAgt.substring(this.verOffset + 8);
      }
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((this.verOffset = this.nAgt.indexOf('MSIE')) != -1) {
      this.browserName = 'Microsoft Internet Explorer';
      this.fullVersion = this.nAgt.substring(this.verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((this.verOffset = this.nAgt.indexOf('Chrome')) != -1) {
      this.browserName = 'Chrome';
      this.fullVersion = this.nAgt.substring(this.verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((this.verOffset = this.nAgt.indexOf('Safari')) != -1) {
      this.browserName = 'Safari';
      this.fullVersion = this.nAgt.substring(this.verOffset + 7);
      if ((this.verOffset = this.nAgt.indexOf('Version')) != -1)
        this.fullVersion = this.nAgt.substring(this.verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((this.verOffset = this.nAgt.indexOf('Firefox')) != -1) {
      this.browserName = 'Firefox';
      this.fullVersion = this.nAgt.substring(this.verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((this.nameOffset = this.nAgt.lastIndexOf(' ') + 1) <
      (this.verOffset = this.nAgt.lastIndexOf('/'))) {
      this.browserName = this.nAgt.substring(this.nameOffset, this.verOffset);
      this.fullVersion = this.nAgt.substring(this.verOffset + 1);
      if (this.browserName.toLowerCase() == this.browserName.toUpperCase()) {
        this.browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((this.ix = this.fullVersion.indexOf(';')) != -1)
      this.fullVersion = this.fullVersion.substring(0, this.ix);
    if ((this.ix = this.fullVersion.indexOf(' ')) != -1)
      this.fullVersion = this.fullVersion.substring(0, this.ix);

    this.majorVersion = parseInt('' + this.fullVersion, 10);
    if (isNaN(this.majorVersion)) {
      this.fullVersion = '' + parseFloat(navigator.appVersion);
      this.majorVersion = parseInt(navigator.appVersion, 10);
    }

    return (''
      + 'Browser name  = ' + this.browserName + '<br>'
      + 'Full version  = ' + this.fullVersion + '<br>'
      + 'Major version = ' + this.majorVersion + '<br>'
      + 'navigator.appName = ' + navigator.appName + '<br>'
      + 'navigator.userAgent = ' + navigator.userAgent + '<br>'
    )

  }
}
