import { Component, OnInit } from '@angular/core';
import {  Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA,MatDialog} from '@angular/material/dialog';
//import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-password-dilalog',
  templateUrl: './password-dilalog.component.html',
  styleUrls: ['./password-dilalog.component.css']
})
export class PasswordDilalogComponent  {
  constructor(
    public dialogRef: MatDialogRef<PasswordDilalogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
}