import { Pipe, PipeTransform } from '@angular/core';
import { AppstorageService } from '../services';

@Pipe({
  name: 'adminPermission',
})
export class AdminPermissionPipe implements PipeTransform {
  constructor(private appstorageservice: AppstorageService) {}
  transform(value: any, ...args: any[]): any {
    let PERMISSION: any = '';
    const user = JSON.parse(this.appstorageservice.getdata('current_user'));
    if (user.userType == 1) {
      PERMISSION = {
        dashboard: {
          add: true,
          edit: true,
          list: true,
          delete: true,
        },
        user: {
          add: true,
          edit: true,
          list: true,
          delete: true,
          download: true,
        },
        match: {
          add: true,
          edit: true,
          list: true,
          delete: true,
          download: true,
        },
        quiz: {
          add: true,
          edit: true,
          list: true,
          delete: true,
        },
        poll: {
          add: true,
          edit: true,
          list: true,
          delete: true,
        },
        venue: {
          add: true,
          edit: true,
          list: true,
          delete: true,
        },
        ads: {
          add: true,
          edit: true,
          list: true,
          delete: true,
        },
        giftSubscription: {
          add: true,
          edit: true,
          list: true,
          delete: true,
        },
        competition: {
          add: true,
          edit: true,
          list: true,
          delete: true,
        },
        pointSystem: {
          add: true,
          edit: true,
          list: true,
          delete: true,
        },
        role: {
          add: true,
          edit: true,
          list: true,
          delete: true,
        },
        subAdmin: {
          add: true,
          edit: true,
          list: true,
          delete: true,
        },
        player: {
          add: true,
          edit: true,
          list: true,
          delete: true,
        },
        planManagement: {
          add: true,
          edit: true,
          list: true,
          // "delete": true
        },
        customNotification: {
          add: true,
          // "edit": true,
          list: true,
          delete: true,
        },
        companyInformation: {
          add: true,
          edit: true,
          list: true,
          // "delete": true
        },
        rewardManagement: {
          add: true,
          edit: true,
          // "list": true,
          // "delete": true
        },
        advertise: {
          add: true,
          edit: true,
          list: true,
          // "delete": true
        },
        report: {
          //"add": true,
          // "edit": true,
          list: true,
         // download: true,
          // "delete": true
        },
        matchReport: {
          //"add": true,
          // "edit": true,
         // list: true,
          download: true,
          // "delete": true
        },
        userLogReport: {
          //"add": true,
          // "edit": true,
          //list: true,
          download: true,
          // "delete": true
        },
        subscriptionReport: {
          //"add": true,
          // "edit": true,
          //list: true,
          download: true,
          // "delete": true
        },
        rewardPointReport: {
          //"add": true,
          // "edit": true,
          //list: true,
          download: true,
          // "delete": true
        },
        manageAddLoocation: {
          "add": true,
           "edit": true,
          "list": true,
           "delete": true
        },
        subscriptionManagement: {
          add: true,
           edit: true,
          list: true,
         // download: true,
           delete: true
        },
        dynamicPages: {
          add: true,
          edit: true,
          //"list": true,
          // "delete": true
        },
      };
      
    } else {
     
      // PERMISSION = this.appstorageservice.getdata('CRICKPERMISSIONS')
      //    ? JSON.parse(this.appstorageservice.getdata('CRICKPERMISSIONS'))
      //    : '';
      // PERMISSION = PERMISSION ? JSON.parse(PERMISSION[0].access) : '';

      PERMISSION = this.appstorageservice.getdata('CRICKPERMISSIONS') ? JSON.parse(this.appstorageservice.getdata('CRICKPERMISSIONS')) : '';
      console.log(PERMISSION)

      PERMISSION = PERMISSION ? JSON.parse(PERMISSION.access) : ''
      console.log(PERMISSION)
      
    }

    if (
      value !== undefined &&
      value !== null &&
      value != '' &&
      PERMISSION != ''
      
    ) {
      console.log(PERMISSION)
      let mod = value.split('_')[0];
      let modv = value.split('_')[1];
    
      return PERMISSION[mod][modv];
    } else {
      return '';
    }
  }
}
