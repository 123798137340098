import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'charLimit'
})
export class CharLimitPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const limit = args ? parseInt(args[0], 10) : 10;
    const trail = ' <span class="text-red">...</span>';
    if (value !== undefined || value !== null || value !== '') {
      return value.length > limit ? value.substring(0, limit) + trail : value;
    }
  }

}
