<app-loader *ngIf="isLoading"></app-loader>
<div class="card mb-2">
    <div class="card-header">
        Featured
    </div>
    <div class="card-body  scoreColumn">
        <div>
            <h5 mb-1>
                {{matchDetailObject.teams['a']?.name}}
            </h5>
            <h5 *ngIf="matchDetailObject.teams.a?.a_1_score.runs || matchDetailObject.teams.a?.a_1_score.wickets">
                {{matchDetailObject.teams.a?.a_1_score.runs}}/{{matchDetailObject.teams.a?.a_1_score.wickets}}
            </h5>
            <h5 *ngIf="!(matchDetailObject.teams.a?.a_1_score.runs ||matchDetailObject.teams.a?.a_1_score.wickets)">
                0/0
            </h5>
            <p class="labelColor">Over
                {{matchDetailObject.teams.a?.a_1_score.overs?matchDetailObject.teams.a?.a_1_score.overs:0}}</p>
            <h5 *ngIf="matchDetailObject.teams.a?.a_2_score.runs || matchDetailObject.teams.a?.a_2_score.wickets">
                {{matchDetailObject.teams.a?.a_2_score.runs}}/{{matchDetailObject.teams.a?.a_2_score.wickets}}
            </h5>
            <h5 *ngIf="!(matchDetailObject.teams.a?.a_2_score.runs ||matchDetailObject.teams.a?.a_2_score.wickets)">
                0/0
            </h5>
            <p class="labelColor">Over
                {{matchDetailObject.teams.a?.a_2_score.overs?matchDetailObject.teams.a?.a_2_score.overs:0}}</p>
        </div>
        <div class="cirlularText rounded-circle">VS</div>
        <div>
            <h5 mb-1>
                {{matchDetailObject.teams.b?.name}}
            </h5>
            <h5 *ngIf="matchDetailObject.teams.b?.b_1_score.runs || matchDetailObject.teams.b?.b_1_score.wickets">
                {{matchDetailObject.teams.b?.b_1_score.runs}}/{{matchDetailObject.teams.b?.b_1_score.wickets}}
            </h5>
            <h5 *ngIf="!(matchDetailObject.teams.b?.b_1_score.runs || matchDetailObject.teams.b?.b_1_score.wickets)">
                0/0
            </h5>
            <p class="labelColor">Over
                {{matchDetailObject.teams.b?.b_1_score.overs?matchDetailObject.teams.b?.b_1_score.overs:0}}</p>
            <h5 *ngIf="matchDetailObject.teams.b?.b_2_score.runs || matchDetailObject.teams.b?.b_2_score.wickets">
                {{matchDetailObject.teams.b?.b_2_score.runs}}/{{matchDetailObject.teams.b?.b_2_score.wickets}}
            </h5>
            <h5 *ngIf="!(matchDetailObject.teams.b?.b_2_score.runs || matchDetailObject.teams.b?.b_2_score.wickets)">
                0/0
            </h5>
            <p class="labelColor">Over
                {{matchDetailObject.teams.b?.b_2_score.overs?matchDetailObject.teams.b?.b_2_score.overs:0}}</p>
        </div>


    </div>
    <div class="text-center cm-bg">
        <p>{{matchDetailObject.now.req?.runs_str}}</p>
    </div>
</div>
<div class="card">
    <div class="card-body p-0">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Batsman</th>
                        <th scope="col">R</th>
                        <th scope="col">B</th>
                        <th scope="col">4s</th>
                        <th scope="col">6s</th>
                        <th scope="col">SR</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td scope="row">{{matchDetailObject.now.striker?.fullname}}*</td>
                        <td>{{matchDetailObject.now.striker?.batting?.runs}}</td>
                        <td>{{matchDetailObject.now.striker?.batting?.balls}}</td>
                        <td>{{matchDetailObject.now.striker?.batting?.fours}}</td>
                        <td>{{matchDetailObject.now.striker?.batting?.sixes}}</td>
                        <td>{{matchDetailObject.now.striker?.batting?.strike_rate}}</td>
                    </tr>
                    <tr>
                        <td scope="row">{{matchDetailObject.now.nonstriker?.fullname}}</td>
                        <td>{{matchDetailObject.now.nonstriker?.batting?.runs}}</td>
                        <td>{{matchDetailObject.now.nonstriker?.batting?.balls}}</td>
                        <td>{{matchDetailObject.now.nonstriker?.batting?.fours}}</td>
                        <td>{{matchDetailObject.now.nonstriker?.batting?.sixes}}</td>
                        <td>{{matchDetailObject.now.nonstriker?.batting?.strike_rate}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-body p-0">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Bowler</th>
                    <th scope="col">O</th>
                    <th scope="col">M</th>
                    <th scope="col">R</th>
                    <th scope="col">W</th>
                    <th scope="col">ER</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td scope="row">{{matchDetailObject.now.bowler?.name}} *</td>
                    <td>{{matchDetailObject.now.bowler?.bowling?.overs}}</td>
                    <td>{{matchDetailObject.now.bowler?.bowling?.maiden_overs}}</td>
                    <td>{{matchDetailObject.now.bowler?.bowling?.runs}}</td>
                    <td>{{matchDetailObject.now.bowler?.bowling?.wickets}}</td>
                    <td>{{matchDetailObject.now.bowler?.bowling?.economy}}</td>
                </tr>
                <tr>
                    <td scope="row">{{matchDetailObject.now.lastBowler?.name}}</td>
                    <td>{{matchDetailObject.now.lastBowler?.bowling?.overs}}</td>
                    <td>{{matchDetailObject.now.lastBowler?.bowling?.maiden_overs}}</td>
                    <td>{{matchDetailObject.now.lastBowler?.bowling?.runs}}</td>
                    <td>{{matchDetailObject.now.lastBowler?.bowling?.wickets}}</td>
                    <td>{{matchDetailObject.now.lastBowler?.bowling?.economy}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="card">
    <div class="card-body p-0  last24Ballscrollable">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Last 24 Ball</th>
                    <th scope="col" *ngFor="let item of matchDetailObject.now.last24Balls">
                        {{item}}
                    </th>
                </tr>
            </thead>
        </table>
    </div>
</div>
<div class="card">
    <div class="card-body p-0">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Extra</th>
                    <th scope="col" *ngIf="matchDetailObject.innings_batting_order.length">
                        {{matchDetailObject.innings_batting_order[matchDetailObject.innings_batting_order.length-1]?.extras}}(B
                        {{matchDetailObject.innings_batting_order[matchDetailObject.innings_batting_order.length-1]?.bye}},LB
                        {{matchDetailObject.innings_batting_order[matchDetailObject.innings_batting_order.length-1]?.legbye}},
                        W
                        {{matchDetailObject.innings_batting_order[matchDetailObject.innings_batting_order.length-1]?.wide}},
                        NB
                        {{matchDetailObject.innings_batting_order[matchDetailObject.innings_batting_order.length-1]?.noball}},
                        P
                        {{matchDetailObject.innings_batting_order[matchDetailObject.innings_batting_order.length-1]?.penalty}})
                    </th>
                    <th scope="col" *ngIf="!(matchDetailObject.innings_batting_order.length)">0(B 0,LB 0, W 0, NB 0, P
                        0)</th>
                </tr>
            </thead>

        </table>
    </div>
</div>
<div class="card">
    <div class="card-body p-0 commentryscrollable">
        <form [formGroup]="searchForm" (ngSubmit)="formActionAddEdit(searchForm.value)" enctype="multipart/form-data"
            method="post">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-6 mb-2">
                        <select class="form-control" formControlName="currentTeam" name="currentTeam">
                            <option class="form-control" value="null">
                                Select Here
                            </option>
                            <option *ngFor="let item of teams" value="{{item?.currentTeam}}">
                                {{item?.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 col-6 mb-2">
                        <select class="form-control" formControlName="currentInning" name="currentInning">
                            <option class="form-control" value="null">
                                Select Here
                            </option>
                            <option value="1">1
                            </option>
                            <option value="2">2
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4 col-10 mb-2">
                        <input class="form-control form-control-navbar" #inputSearch type="search" placeholder="Search"
                            aria-label="Search" style="
                          border: 1px solid #ced4da;
                          position: relative;
                          margin-left: auto;
                        " />
                        <div class="input-group-append" style="position: absolute; right: 3px; top: 13px">
                            <button class="btn btn-navbar" type="submit">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-1 col-2 mb-2">
                        <i class="fa fa-times" aria-hidden="true" (click)="cancelAddEditDelete()" style="
                          color: #ffc107;
                          font-size: 30px;
                          margin-top: 5px;
                        "></i>
                    </div>
                </div>
            </div>
        </form>
        <table class="table ">
            <thead>
                <tr>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of commentry;let i = index">
                    <td class="commentry-left" [ngClass]="{'commentry-left':selectedIndex!=-1}">
                        <span
                            *ngIf="item.over_str!='' && item.ball_type!='penalty' && selectedIndex==i &&('match_add' | adminPermission)">
                            <i class=" fa fa-plus-circle" aria-hidden="true" (click)="addBall(item)" style="
                          color: #ffc107;
                          font-size: 30px;
                          margin-top: 0px;
                        "></i>
                        </span>
                        <span *ngIf="(item.over_str!='' && selectedIndex===i)&&('match_delete' | adminPermission)"><img
                                src="assets/minus.png" width="28" height="28" style="margin: 0px 0px 0px 0px"
                                (click)="deleteBall(item)" /></span>
                        <span
                            *ngIf="item.over_str!='' && item.ball_type!='penalty'&& selectedIndex===i && ('match_edit' | adminPermission)">
                            <button tooltip="Click to Edit ball" placement="top" type="button"
                                class="btn btn-nradius btn-danger btn-xs" (click)="editBall(item)">
                                <i class="fas fa-edit"></i>
                            </button>
                        </span>
                        <span
                            *ngIf="item.over_str!='' && item.ball_type!='penalty'&& selectedIndex===i && ('match_edit' | adminPermission)">
                            <button tooltip="Click to edit Wagon Wheel " placement="top" type="button"
                                class="btn btn-nradius btn-danger btn-xs" (click)="editWragonWheel(item)">
                                <i class="fas fa-dharmachakra"></i>
                            </button>
                        </span>
                    </td>
                    <td>
                        <span class=" tag red" *ngIf="item.wicket=='1'">{{item.runs!='0'?item.runs:''}} W </span>
                        <span class="tag lightGreen" *ngIf="item.ball_type=='wide'">{{item.runs}} wd</span>
                        <span class="tag darkGreen" *ngIf="item.ball_type=='noball'">{{item.runs}} NB</span>
                        <span class="tag lightGreen" *ngIf="item.ball_type=='legbye'">{{item.runs}} LB</span>
                        <span class="tag lightGreen" *ngIf="item.ball_type=='bye'">{{item.runs}} B</span>
                        <span class="tag darkGreen" *ngIf="item.ball_type=='penalty'">{{item.runs}} P</span>
                        <span class="tag gray" *ngIf="item.ball_type=='normal'&& item.runs==0"> {{item.runs}}</span>
                        <span class="tag lightGreen"
                            *ngIf="item.ball_type=='normal'&& item.runs!=4 && item.runs!=6 &&item.runs!=0">
                            {{item.runs}}</span>
                        <span class="tag darkGreen" *ngIf="item.ball_type=='normal' && (item.runs==4||item.runs==6)">
                            {{item.runs}}</span>
                    </td>
                    <td scope="row">{{item.over_str}}</td>
                    <td (click)="addDeleteEditOption(i)" [innerHtml]="item.comment"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-template #eventDetails let-close="close">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="modal-header-event fade">
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark wicketmodal">
        <h3>{{comment}}</h3>
        <br>
        <form [formGroup]="batsManForm" (ngSubmit)="formActionAddEdit(batsManForm.value)" method="post">
            <div class="row">
                <div class="col-md-12">
                    <label class="radio-inline">
                        <input type="radio" value="batsman1" formControlName="batsman">&nbsp;&nbsp;
                        {{batsman1}}
                    </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <label class="radio-inline">
                        <input type="radio" value="batsman2" formControlName="batsman">&nbsp;&nbsp;&nbsp;
                        {{batsman2}}
                    </label>
                    <label class="radio-inline">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bowler : &nbsp;&nbsp;
                        {{bowler}}
                    </label>
                </div>
            </div>
        </form>
        <div id="mainValueButton">


            <div class="row">
                <div class="col-md-6">
                    <h3 *ngIf="scoreNumberArray.length"><b
                            *ngFor="let item of scoreNumberArray">{{item}}&nbsp;&nbsp;</b></h3>
                    <h3 *ngIf="scoreStringArray.length"><b
                            *ngFor="let item of shortStringArray">{{item}}&nbsp;&nbsp;</b></h3>
                </div>
                <div class="col-md-3 pl-1 pr-1 shortRun">
                    <label>
                        <input type="checkbox" [checked]="params.mute" [value]="params.mute"
                            (change)="onCheckboxChange($event)" />
                        Mute & Text Off
                    </label>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="commentryStringArray.includes('Catch Drop')"
                            (click)="tickMarkFunc('Catch Drop')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor5"
                            (click)="getButtonValue('Catch Drop',$event)">
                            Catch Drop
                        </button></span>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreStringArray.includes('shortRun')" (click)="tickMarkFunc('shortRun')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor5"
                            (click)="getButtonValue('shortRun',$event)">
                            Short Run
                        </button>
                    </span>
                </div>
                <div class="col-md-3 pl-1 pr-1">
                    <span class="iconbtn-wrapper">
                        <i *ngIf="commentryStringArray.includes('Free Hit')" (click)="tickMarkFunc('Free Hit')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor5"
                            (click)="getButtonValue('Free Hit',$event)">
                            Free Hit
                        </button>
                    </span>

                    <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor1"
                        (click)="openPenalty()">
                        Penalty
                    </button>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreStringArray.indexOf('Wkt Confirm') > -1"
                            (click)="tickMarkFunc('Wkt Confirm')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor8"
                            (click)="openWicket()">
                            Wkt Confirm
                        </button></span>
                </div>
            </div>
            <div class="row btn-group">
                <div class="col-md-3 pl-1 pr-1 flex-end">
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor1"
                        (click)="getButtonValue('Ball Start',$event)">
                        Ball Start
                    </button>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor2 font-weight-bold"
                        (click)="getButtonValue('1 OR 2',$event)">
                        1 OR 2
                    </button>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor3 font-weight-bold"
                        (click)="getButtonValue('2 OR 3',$event)">
                        2 OR 3
                    </button>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor23 font-weight-bold"
                        (click)="getButtonValue('4 OR 6',$event)">
                        4 OR 6
                    </button>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreStringArray.includes('wide')" (click)="tickMarkFunc('wide')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor4"
                            (click)="getButtonValue('wide',$event)">
                            Wide
                        </button>
                    </span>
                </div>
                <div class="col-md-3 pl-1 pr-1 flex-end">
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreNumberArray.indexOf(0) > -1" (click)="tickMarkFunc(0)">✓</i>
                        <button type="button"
                            class="btn btn-primary w-100 mb-2 button_height btncolor2 font-weight-bold"
                            (click)="getButtonValue(0,$event)">
                            0
                        </button>
                    </span>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreNumberArray.includes(1)" (click)="tickMarkFunc(1)">✓</i>
                        <button type="button"
                            class="btn btn-primary w-100 mb-2 button_height btncolor5 font-weight-bold"
                            (click)="getButtonValue(1,$event)">
                            1
                        </button>
                    </span>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreNumberArray.includes(2)" (click)="tickMarkFunc(2)">✓</i>
                        <button type="button"
                            class="btn btn-primary w-100 mb-2 button_height btncolor6 font-weight-bold"
                            (click)="getButtonValue(2,$event)">
                            2
                        </button></span>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreNumberArray.includes(4)" (click)="tickMarkFunc(4)">✓</i>
                        <button type="button"
                            class="btn btn-primary w-100 mb-2 button_height btncolor7 font-weight-bold"
                            (click)="getButtonValue(4,$event)">
                            4
                        </button></span>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreNumberArray.includes(6)" (click)="tickMarkFunc(6)">✓</i>
                        <button type="button"
                            class="btn btn-primary w-100 mb-2 button_height btncolor26 font-weight-bold"
                            (click)="getButtonValue(6,$event)">
                            6
                        </button></span>
                </div>
                <div class="col-md-3 pl-1 pr-1 flex-end">
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreStringArray.includes('wicket')" (click)="tickMarkFunc('wicket')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor8"
                            (click)="getButtonValue('Wicket',$event)">
                            Wicket
                        </button>
                    </span>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor3"
                        (click)="getButtonValue('Hawa mei',$event)">
                        Hawa mei
                    </button>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreNumberArray.includes(3)" (click)="tickMarkFunc(3)">✓</i>
                        <button type="button"
                            class="btn btn-primary w-100 mb-2 button_height btncolor3 font-weight-bold"
                            (click)="getButtonValue(3,$event)">
                            3
                        </button></span>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreStringArray.includes('noball')" (click)="tickMarkFunc('noball')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor5"
                            (click)="getButtonValue('noball',$event)">
                            No Ball
                        </button>
                    </span>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor9"
                        (click)="getButtonValue('Hit/Maara',$event)">
                        Hit/Maara
                    </button>
                </div>
                <div class="col-md-3 pl-1 pr-1">
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreStringArray.includes('legbye')" (click)="tickMarkFunc('legbye')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor10"
                            (click)="getButtonValue('legbye',$event)">
                            Legbye
                        </button>
                    </span>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreStringArray.includes('bye')" (click)="tickMarkFunc('bye')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor11"
                            (click)="getButtonValue('bye',$event)">
                            Bye
                        </button>
                    </span>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor12"
                        (click)="getButtonValue('Dead Ball',$event)">
                        Dead Ball
                    </button>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor3"
                        (click)="getButtonValue('Bowler Stop',$event)">
                        Bowler Stop
                    </button>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="scoreStringArray.includes('overthrow')" (click)="tickMarkFunc('overthrow')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor13"
                            (click)="getButtonValue('overthrow',$event)">
                            Overthrow
                        </button>
                    </span>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="commentryStringArray.includes('Third Empire')"
                            (click)="tickMarkFunc('Third Empire')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor14"
                            (click)="getButtonValue('Third Empire',$event)">
                            Third Empire
                        </button></span>
                    <span class="iconbtn-wrapper">
                        <i *ngIf="commentryStringArray.includes('Appeal')" (click)="tickMarkFunc('Appeal')">✓</i>
                        <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor14"
                            (click)="getButtonValue('Appeal',$event)">
                            Appeal
                        </button></span>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor8"
                        (click)="openReviewModal()">
                        Review
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 pl-1 pr-1">
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor15"
                        [ngClass]="{'flashing_effect':scoreNumberArray.length>0||scoreStringArray.length>0}"
                        (click)="getButtonValue('done',$event)">
                        Done
                    </button>
                </div>
                <div class="col-md-3 pl-1 pr-1">
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor16"
                        (click)="getButtonValue('Undo',$event)">
                        Undo
                    </button>
                </div>
                <div class="col-md-3 pl-1 pr-1">
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor17">
                        Break
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #wicketModal let-close="close">
    <div class="modal-header-event fade">
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark wicketmodal">
        <form [formGroup]="wicketForm" (ngSubmit)="formActionAddEdit(wicketForm.value)" method="post">
            <div class="row">
                <div class="col-md-6">
                    <label class="radio-inline">
                        <input type="radio" value="batsman1" formControlName="batsman">&nbsp;&nbsp;
                        {{batsman1}}
                    </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <label class="radio-inline">
                        <input type="radio" value="batsman2" formControlName="batsman">&nbsp;&nbsp;&nbsp;
                        {{batsman2}}
                    </label>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Fielder</label>
                        <div class="col-sm-8">

                            <ng-multiselect-dropdown  id="bowler" [placeholder]="'Select Fielder'"
                                [settings]="dropdownSettings" [data]="bowlerList" [(ngModel)]="selectedItems"
                                formControlName="bowler">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-md-12 wicketDirclass" #wicketTemp id="wicketButtons">
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Caught')" (click)="tickMarkFunc('Caught')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor18 "
                        (click)="getButtonValue('Caught',$event)">
                        Caught
                    </button></span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Caught Behind')" (click)="tickMarkFunc('Caught Behind')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor19"
                        (click)="getButtonValue('Caught Behind',$event)">
                        Caught Behind
                    </button></span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('LBW')" (click)="tickMarkFunc('LBW')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor20"
                        (click)="getButtonValue('LBW',$event)">
                        LBW
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Bowled')" (click)="tickMarkFunc('Bowled')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor18"
                        (click)="getButtonValue('Bowled',$event)">
                        Bowled
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('RunOut')" (click)="tickMarkFunc('RunOut')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor20"
                        (click)="getButtonValue('RunOut',$event)">
                        RunOut
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Caught & Bowled')"
                        (click)="tickMarkFunc('Caught & Bowled')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor21"
                        (click)="getButtonValue('Caught & Bowled',$event)">
                        Caught & Bowled
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Stumped')" (click)="tickMarkFunc('Stumped')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor21"
                        (click)="getButtonValue('Stumped',$event)">
                        Stumped
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Mankading')" (click)="tickMarkFunc('Mankading')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor18"
                        (click)="getButtonValue('Mankading',$event)">
                        Mankading
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Timed Out')" (click)="tickMarkFunc('Timed Out')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor20"
                        (click)="getButtonValue('Timed Out',$event)">
                        Timed Out
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Handling Ball')" (click)="tickMarkFunc('Handling Ball')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor21"
                        (click)="getButtonValue('Handling Ball',$event) ">
                        Handling Ball
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Obstructing field')"
                        (click)="tickMarkFunc('Obstructing field')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor19"
                        (click)="getButtonValue('Obstructing field',$event)">
                        Obstructing field
                    </button></span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Retired Hurt')" (click)="tickMarkFunc('Retired Hurt')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor21"
                        (click)="getButtonValue('Retired Hurt',$event)">
                        Retired Hurt
                    </button></span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Hitting Ball twice')"
                        (click)="tickMarkFunc('Caught & Bowled')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor22"
                        (click)="getButtonValue('Hitting Ball twice',$event)">
                        Hitting Ball twice
                    </button></span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 pr-1 pl-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor8"
                    (click)="getButtonValue('wicketDone',wicketTemp)">
                    Done
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #penalty let-close="close">
    <div class="modal-header-event fade">
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="penaltyForm" (ngSubmit)="formActionAddEditPenalty(penaltyForm.value)"
        enctype="multipart/form-data" method="post">
        <app-loader *ngIf="isLoading"></app-loader>
        <div class="modal-body text-dark wicketmodal">

            <div class="form-horizontal">
                <div class="form-group row">
                    <label class="col-md-3 col-form-label label_custom_clss" for="run">Enter Run</label>
                    <div class="col-md-9">
                        <input type="text" name="run" class="form-control number" placeholder="Enter Run"
                            autocomplete="off" formControlName="run" [ngClass]="{
                    'is-invalid':
                      penaltyForm.get('run').touched &&
                      penaltyForm.get('run').invalid}" required />
                        <div class="invalid-feedback">
                            <div>Run is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label label_custom_clss" for="studentUserName">Enter Reason</label>
                    <div class="col-md-9">
                        <input type="text" name="reason" class="form-control" placeholder="Reason" autocomplete="off"
                            formControlName="reason" [ngClass]="{
                    'is-invalid':
                      penaltyForm.get('reason').touched &&
                      penaltyForm.get('reason').invalid}" required />
                        <div class="invalid-feedback">
                            <div>Reason is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label label_custom_clss" for="currentInning">Current Inning</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="currentInning" [ngClass]="{
                            'is-invalid':
                              penaltyForm.get('currentInning').touched &&
                              penaltyForm.get('currentInning').invalid}">
                            <option value="null">Select Inning</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Current Inning is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label label_custom_clss" for="currentTeam">Select Team</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="currentTeam" [ngClass]="{
                    'is-invalid':
                      penaltyForm.get('currentTeam').touched &&
                      penaltyForm.get('currentTeam').invalid}" required>
                            <option value="null">Select Team</option>
                            <option *ngFor="let item of teams" value="{{item.currentTeam}}">
                                {{item.name}}
                            </option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Team is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label label_custom_clss" for="currentTeam">Select Team</label>
                    <div class="col-md-9">
                        <label>
                            <input type="checkbox" formControlName="isAddDelivery" />
                            Delivery
                        </label>&nbsp;&nbsp;
                        <label>
                            <input type="checkbox" formControlName="isAddBatsman"
                                (change)="onCheckBoxPenalty($event)" />
                            Batsman
                        </label>

                        <div class="invalid-feedback">
                            <div>Team is required</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-flat btn-warning update_btn_class" value="submit">
                &nbsp;&nbsp;Add &nbsp;&nbsp;</button>&nbsp;&nbsp;
        </div>
    </form>
</ng-template>
<ng-template #review let-close="close">
    <div class="modal-header-event fade">
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true" style="font-size: xxx-large;margin-right: 13px;">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark icontopright" id="reviewButtons">
        <div class="row">
            <div class="col-md-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Bowling Review')"
                        (click)="tickMarkFunc('Bowling Review')">✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Bowling Review')">
                        Bowling Review
                    </button></span>
            </div>
            <div class="col-md-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Bating Review')" (click)="tickMarkFunc('Bating Review')">✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Bating Review')">
                        Bating Review
                    </button></span>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="reviewSecondRow!=-1">
            <div class="col-md-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Out')" (click)="tickMarkFunc('Out')">✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Out')">
                        Out</button></span>
            </div>
            <div class="col-md-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Not Out')" (click)="tickMarkFunc('Not Out')">✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Not Out')">
                        Not Out
                    </button></span>
            </div>
        </div>
        <br *ngIf="reviewThirdRow!=-1">
        <div class="row" *ngIf="reviewThirdRow!=-1">
            <div class="col-md-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Review Lost')" (click)="tickMarkFunc('Review Lost')">✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Review Lost')">
                        Review Lost
                    </button></span>
            </div>
            <div class="col-md-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Umpires Call')" (click)='tickMarkFunc("Umpires Call")'>✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Umpires Call')">
                        Umpire's Call</button></span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
    </div>
</ng-template>
<ng-template #wagonWheel let-close="close">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="modal-header-event fade">
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true" style="font-size: xxx-large;margin-right: 13px;">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark icontopright" id="reviewButtons">
        <div class="row">
            <div class="col-md-6 col-12">
                <h2>{{ballData.striker}} ({{ballData.batting_style[0]}})</h2>
                <canvas id='pieChart'></canvas>
            </div>
            <div class="col-md-6 col-12">
                <!-- <h3>{{ ballData | json }}</h3> -->
                <!-- <h3>{{ballData?.bowler?.key}} ({{ballData?.bowling_style[0]}})</h3> -->
                <h3>{{ballData?.bowler?.key}} ({{ballData?.bowling_style[0]}})</h3>
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12 col-12">
                        <div class="matrix">
                            <table class="table table-bordered wrangonTable">
                                <tr *ngIf="wragonWheelObj.bowler_right_left_direction=='rhb'">
                                    <th colspan="6" style="text-align: center;">RHB</th>
                                </tr>
                                <tr *ngIf="wragonWheelObj.bowler_right_left_direction=='lhb'">
                                    <th colspan="6" style="text-align: center;">LHB</th>
                                </tr>
                                <tr>
                                    <td>Full Toss</td>
                                    <td (click)="bowler_direction('00')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='00'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('01')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='01'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('02')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='02'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('03')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='03'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('04')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='04'"
                                            style="font-size:xx-large;">✓</i></td>
                                </tr>
                                <tr>
                                    <td>Yorker</td>
                                    <td (click)="bowler_direction('10')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='10'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('11')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='11'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('12')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='12'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('13')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='13'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('14')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='14'"
                                            style="font-size:xx-large;">✓</i></td>
                                </tr>
                                <tr>
                                    <td>Full Length</td>
                                    <td (click)="bowler_direction('20')"> <i
                                            *ngIf="wragonWheelObj.bowler_direction=='20'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('21')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='21'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('22')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='22'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('23')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='23'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('24')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='24'"
                                            style="font-size:xx-large;">✓</i></td>
                                </tr>
                                <tr>
                                    <td>Good Length</td>
                                    <td (click)="bowler_direction('30')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='30'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('31')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='31'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('32')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='32'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('33')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='33'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('34')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='34'"
                                            style="font-size:xx-large;">✓</i></td>
                                </tr>
                                <tr>
                                    <td>Short of good length</td>
                                    <td (click)="bowler_direction('40')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='40'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('41')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='41'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('42')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='42'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('43')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='43'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('44')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='44'"
                                            style="font-size:xx-large;">✓</i></td>
                                </tr>
                                <tr>
                                    <td>Short Length</td>
                                    <td (click)="bowler_direction('50')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='50'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('51')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='51'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('52')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='52'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('53')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='53'"
                                            style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('54')"><i
                                            *ngIf="wragonWheelObj.bowler_direction=='54'"
                                            style="font-size:xx-large;">✓</i></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-flat btn-warning update_btn_class" value="submit"
            (click)="sendWragonWheelData()">
            &nbsp;&nbsp;Add &nbsp;&nbsp;</button>&nbsp;&nbsp;
    </div>
</ng-template>