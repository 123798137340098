<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>
        </li>
    </ul>
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#">
                <img class="header-profile-img elevation-3 img-circle" src="assets/img/profile.png">
            </a>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <!-- <a [routerLink]="['/profile']" routerLinkActive="router-link-active" class="dropdown-item">
                    <i class="fas fa-envelope mr-2"></i> Change Password
                </a>
                <div class="dropdown-divider"></div>-->
                <a [routerLink]="['/profileupdate']" routerLinkActive="router-link-active" class="dropdown-item">
                    <i class="fas fa-user-circle mr-2"></i> Update Profile
                </a>
                <a [routerLink]="['/change-password']" routerLinkActive="router-link-active" class="dropdown-item">
                    <i class="fas fa-user-circle mr-2"></i> Change Password
                </a> 
                <div class="dropdown-divider"></div>
                <a href="javascript:void(0)" (click)="logout()" class="dropdown-item">
                    <i class="fas fa-envelope mr-2"></i> Logout
                </a>
            </div>
        </li>
    </ul>
</nav>