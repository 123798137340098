import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './../../services';
import { NgModule } from '@angular/core';
@Injectable()
@NgModule({
  providers: []
})
export class LoggedinGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }
  canActivate() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/home']);
    }
    return true;
  }
}
