import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket;
  constructor() { }

  setupSocketConnection() {
    this.socket = io(environment.SOCKET_ENDPOINT,{transports: ['websocket'] });
    // this.socket = io(environment.SOCKET_ENDPOINT);
    console.log('Socket connectionn',environment.SOCKET_ENDPOINT);
    
  }
  ReceiveData() {
    this.socket.on("homeCommentary", function (params) {
      console.log("check", params);
    })
  }
  ReceiveLiveData(eventName: string) {
    return new Observable((subcriber) => {
      this.socket.on(eventName, (data) => {
        subcriber.next(data)
      })
    })
  }
  ReceiveNewBallData(eventName: string) {
    return new Observable((subcriber) => {
      this.socket.on(eventName, (data) => {
        subcriber.next(data)
      })
    })
  }
  addNewBallData(eventName: string) {
    return new Observable((subcriber) => {
      this.socket.on(eventName, (data) => {
        subcriber.next(data)
      })
    })
  }
  deleteBallData(eventName: string) {
    return new Observable((subcriber) => {
      this.socket.on(eventName, (data) => {
        subcriber.next(data)
      })
    })
  }
  editBallData(eventName: string) {
    return new Observable((subcriber) => {
      this.socket.on(eventName, (data) => {
        subcriber.next(data)
      })
    })
  }
  sendData(data) {
    this.socket.emit("homeCommentary", data)
  }
  closeSocket(){
    this.socket.disconnect();
    // this.socket.on("disconnect", () => {
    //   this.socket.connect();
    // });
    
  }
}
