import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { RouterModule, Router, ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";
import { AppstorageService, UserService, NotificationService } from 'src/app/services';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('openModal', { static: false }) openModal: ElementRef;
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  public loginForm: FormGroup;
  constructor(
    private appstorageservice: AppstorageService,
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.loginForm = fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
  }

  ngOnInit() {
    this.userService.notify401Error.subscribe(message => {
      this.openLoginForm(message);
    })
  }
  openLoginForm(message) {
    // if (message == 401) {
    //   let isLoginUser = this.appstorageservice.getdata(environment.localSTORAGE.profile);
    //   isLoginUser = isLoginUser ? JSON.parse(isLoginUser) : { email: '' };
    //   if (isLoginUser.email) {
    //     this.loginForm.patchValue({ email: isLoginUser.email })
    //     this.openModalForm();
    //   } else {
        this.logout()
    //   }
    // }
  }
  logout() {
    this.appstorageservice.removedata(environment.localSTORAGE.token);
    this.appstorageservice.removedata(environment.localSTORAGE.profile);
    return this.router.navigate(['/']);
  }
  userLogin($event: any) {
    this.closeModalForm()
    if (this.loginForm.valid) {
      this.userService
        .login(this.loginForm.value)
        .then(responce => {
          let { user, token } = responce.data;
          this.appstorageservice.setdata(environment.localSTORAGE.token, token, 'token');
          this.appstorageservice.setdata(environment.localSTORAGE.profile, JSON.stringify(user));
          window.location.reload();
        })
        .catch(err => {
          this.notificationService.show(err);
        });
    }
  }
  openModalForm() {
    const ele = this.openModal.nativeElement;
    ele.click();
  } /// end of the openFindAddressPopup function
  closeModalForm() {
    const el = this.closeModal.nativeElement;
    el.click();
  } ///
}
