import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShareModule } from './share/share.module';
import { AppstorageService, AuthService, HomeService, JWTInterceptor, NotificationService, UserService } from './services';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard, LoggedinGuard } from './guards';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SocketioService } from './services/socketio.service';
import { ChracterCheckPipe } from './pipes/chracter-check.pipe';


@NgModule({
  declarations: [
    AppComponent,
    ChracterCheckPipe,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShareModule,
    HttpClientModule,
    HttpModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    AuthGuard,
    LoggedinGuard,
    NgbModule,
  ],
  providers: [UserService,SocketioService,AppstorageService,AuthService,NotificationService,HomeService,{provide:HTTP_INTERCEPTORS,useClass:JWTInterceptor,multi:true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
