
/**
 * Helper class used for a variety of netcode related tasks.
 */
export class NetworkHelper {
    /**
     * Format the error codes for use in the Alert modal.
     *
     
     * @returns {string}
     */
    public static resolveErrorList(response: any) {
        // this error key is the catch's by default key, no need to parse json
        const errors = response.error;

        if (errors && errors.message !== undefined) {
            const keys = Object.keys(errors.message);
            let errorList = ``;
            for (const key of keys) {
                errorList = `${errorList} ${errors.message[key]}`;
            }
            return errorList;
        }
    }

    /**
     * Grabs the error from an ApiResponse and formats it.
     *
     * @param {ApiResponseInterface} response
     * @returns {string}
     */
    public static resolveErrorMessage(response: any) {
        // this error key is the catch's by default key, no need to parse json
        const error = response.error;

        //if (error && error.messages) {
        return `${error.message[0]}`;
        // } else if (error && error.errors && error.errors['name']) {
        //     return `Message: ${error.errors.name}`;
        // } else if (error &&  error.errors && error.errors['password']) {
        //     return `Message: ${error.errors.password}`;
        // } else {
        //     return null;
        // }
    }
}