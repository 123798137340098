// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // apiUrl: 'http://localhost:5001',
  apiUrl:'https://cricradioapi.mobcoder.in',
  // apiUrl: 'https://a91f-43-247-41-114.ngrok.io',
  SOCKET_ENDPOINT: 'https://cricradiosocket.mobcoder.in',
  // SOCKET_ENDPOINT : 'https://dfe9-223-225-72-120.ngrok.io',
  localSTORAGE: {
    token: 'crickAdmin',
    profile: 'adminProfile',
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
