import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  AppstorageService,
  UserService,
  AuthService,
  NotificationService,
  HomeService,
} from '../../services';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.css'],
})
export class LeftSideBarComponent implements OnInit {
  public loginUser: any = '';
  public profileData: any;
  public subscription: any;
  access: any;
  access1: any;
  isLoading: boolean;
  userType: any;
  show = false
  // public isLoading: Boolean = false;
  constructor(
    private appstorageservice: AppstorageService,
    private userService: UserService,
    private router: Router, 
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
  this.adminDetails()
 
    // this.loginUser = JSON.parse(
    //   this.appstorageservice.getdata(environment.localSTORAGE.profile)
    // );
  }

  adminDetails(){
    this.userService
    .adminDetail({ adminId: localStorage.getItem('adminId') })
    .then((res) => {
      if (res.statusCode == 1) {
      
        
          res.responseData.role= res.responseData.role[0];
          this.userType = res.responseData.userType
        this.access = JSON.parse(res.responseData.role?.access)
     
        this.appstorageservice.setdata('current_user', JSON.stringify(res.responseData));
        this.appstorageservice.setdata('CRICKPERMISSIONS', JSON.stringify(res.responseData.role));
      } else {
        this.isLoading = false;
        this.notificationService.show('Please contact to super admin');
      }
    })
    .catch((err) => {
     
      this.isLoading = false;
      this.notificationService.show(err);
    });
  }


  

  closeSidebar(){
   
    document.getElementById('main-sidebar-wrap').style.marginLeft = '-250px';
  }

  isNavbarVisibale = o => !!Object.keys(o).find(k => o[k]);
  

  openSidebar(){
   this.show = !this.show
   
    // var dropdown = document.getElementsByClassName("dropdown-btn");


    // for (let i = 0; i < dropdown.length; i++) {
    //   dropdown[i].addEventListener("click", function() {
    //   this.classList.toggle("active");
    //   var dropdownContent = this.nextElementSibling;
    //   if (dropdownContent.style.display === "block") {
    //   dropdownContent.style.display = "none";
    //   } else {
    //   dropdownContent.style.display = "block";
    //   }
    //   });
    // }



  }

}
