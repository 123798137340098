import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {
  AuthService,
  AppstorageService
} from './../../services';
import { NgModule } from '@angular/core';
@Injectable()
@NgModule({
  providers: []
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private appstorageservice: AppstorageService,
    private router: Router
  ) {
  }
  canActivate() {
    if (this.authService.isLoggedIn()) {
      let currentUser: any = (this.appstorageservice.getdata(environment.localSTORAGE.profile));
      currentUser = currentUser ? JSON.parse(this.appstorageservice.getdata(environment.localSTORAGE.profile)) : '';
      if (currentUser !== '') {
        // return this.router.navigate(['/dashboard']); 
        return true;
      } else {
        return true;
      }
    } else {
      return this.router.navigate(['/']);
    }
  }
}
