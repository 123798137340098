<aside class="main-sidebar elevation-4 sidebar-light-warning">
  <!-- Brand Logo -->
  <a [routerLink]="['/']" routerLinkActive="router-link-active" class="brand-link">
    <img src="assets/img/logo.png" alt="CrickRadio" class="logomain" />
    <!-- <span class="brand-text font-weight-light">UrbanKaksha</span> -->
  </a>
  <a class="nav-link cls-btn-sidebar d-block d-lg-none" data-widget="pushmenu" href="#">
    <i class="fa fa-window-close"></i>
  </a>
  <!-- Sidebar -->
  <div
    class="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
    <div class="os-resize-observer-host">
      <div class="os-resize-observer observed" style="left: 0px; right: auto"></div>
    </div>
    <div class="os-size-auto-observer" style="height: calc(100% + 1px); float: left">
      <div class="os-resize-observer observed"></div>
    </div>
    <div class="os-content-glue" style="margin: 0px -8px; width: 249px; height: 257px"></div>
    <div class="os-padding">
      <div class="os-viewport os-viewport-native-scrollbars-invisible" style="overflow-y: scroll">
        <div class="os-content" style="padding: 0px 8px; height: 100%; width: 100%" id="sidebar-wrapper">
          <!-- Sidebar user panel (optional) -->
          <!-- Sidebar Menu -->
          <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li class="nav-item">
                <a [routerLink]="['/home']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Dashboard </p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.user)">
                <a [routerLink]="['/user']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>User Management</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.match)">
                <a [routerLink]="['/match']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Match Management </p>
                </a>
              </li>
              <!-- <li class="nav-item" *ngIf="'match_list' || 'match_add' || 'match_edit' || 'match_delete' | adminPermission" >
                <a [routerLink]="['/match']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Match Management </p>
                </a>
              </li> -->
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.quiz)">
                <a [routerLink]="['/quiz']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Quiz Management</p>
                </a>
              </li>
              <!-- <li class="nav-item" *ngIf = "userType === 1 || isNavbarVisibale(access.poll)" >
                <a [routerLink]="['/poll']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Poll Management</p>
                </a>
              </li> -->
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.venue)">
                <a [routerLink]="['/venue']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Venue Management </p>
                </a>
              </li>
              <!-- Not geeting player key in backend -->
              <li class="nav-item" *ngIf="userType === 1 ">
                <a [routerLink]="['/player']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Player Management</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.role)">
                <a [routerLink]="['/role']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Role Management</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.subAdmin)">
                <a [routerLink]="['/sub-admin']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Sub Admin Management</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.ads)">
                <a [routerLink]="['/ads']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Ads Management</p>
                </a>
              </li>
              <!-- <li class="nav-item" *ngIf="'competition_list' || 'competition_add' || 'competition_edit' || 'competition_delete' | adminPermission" >
                <a [routerLink]="['/competition']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Competition Management</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="'pointSystem_list' || 'pointSystem_add' || 'pointSystem_edit' || 'pointSystem_delete' | adminPermission" >
                <a [routerLink]="['/point-system']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Point System Management</p>
                </a>
              </li> -->


              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.manageAddLoocation)">
                <a [routerLink]="['/subscription']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Manage Ads Location </p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.giftSubscription)">
                <a [routerLink]="['/subscription-gift/gift-subscription']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Gift your Friend </p>
                </a>

              </li>
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.planManagement)">
                <a [routerLink]="['/plan']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Plan Management</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.rewardManagement)">
                <a [routerLink]="['/reward-point-management']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Reward Point Management</p>
                </a>
              </li>
              <mat-accordion class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.report)">
                <mat-expansion-panel>
                  <mat-expansion-panel-header routerLinkActive="active" class="nav-link report-sidebar"><a
                      routerLinkActive="active" class="nav-link">
                      <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                      <p>Reports</p>
                    </a></mat-expansion-panel-header>
                  <div class="report-submenu">
                    <a [routerLink]="['/reports/subscription-report']" routerLinkActive="active" class="nav-link">
                      <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                      <p>Subscription Reports</p>
                    </a>
                    <a [routerLink]="['/reports/match-report']" routerLinkActive="active" class="nav-link">
                      <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                      <p>Match Reports</p>
                    </a>
                    <a [routerLink]="['/reports/user-log']" routerLinkActive="active" class="nav-link">
                      <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                      <p>User Log</p>
                    </a>
                    <a [routerLink]="['/reports/reward-point-report']" routerLinkActive="active" class="nav-link">
                      <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                      <p>Reward point</p>
                    </a>
                  </div>
                </mat-expansion-panel>

              </mat-accordion>
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.customNotification)">
                <a [routerLink]="['/notification']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Custom Notification</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.advertise)">
                <a [routerLink]="['/advertise']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Advertise</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.dynamicPages)">
                <a [routerLink]="['/aboutus']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Dynamic Pages</p>
                </a>
              </li>






              <li class="nav-item" *ngIf="userType === 1 || isNavbarVisibale(access.companyInformation)">
                <a [routerLink]="['/company-information']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Company Information</p>
                </a>
              </li>
              <li class="nav-item" >
                <a [routerLink]="['/scrapping']" routerLinkActive="active" class="nav-link">
                  <img class="link-img" src="assets/img/Dashboard.png" />&nbsp;
                  <p>Scrapping</p>
                </a>
              </li>



            </ul>
          </nav>

          <!-- /.sidebar-menu -->
        </div>
      </div>
    </div>



  </div>
  <!-- /.sidebar -->
</aside>