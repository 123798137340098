import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'dateFORMATE'
})
export class CustomeDatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (args[0]) {
      return new DatePipe('en-US').transform(value, 'dd-MMM-yyyy');
    }
    return new DatePipe('en-US').transform(value, 'dd-MMM-yyyy') + ` <span class="text-cyan"> ` +
      new DatePipe('en-US').transform(value, 'h:mm a') + `</span>`;
  }

}
