import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chracterCheck'
})
export class ChracterCheckPipe implements PipeTransform {

  transform(val:string , length?: any):string {
    return (val.length>length)? val.slice(0, 15)+'...':val
  }

}
