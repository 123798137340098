import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalized'
})
export class CapitalizedPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value){
      return value;
    } 
    let splitArray = value.split('-');
    if (splitArray.length == 1) {
      return value[0].toUpperCase() + value.substr(1).toLowerCase();;
    }
    else if (splitArray.length == 2) {
      let capitalizedFirst = (splitArray[0]).charAt(0).toUpperCase() + (splitArray[0]).slice(1);
      let capitalizedSecond = (splitArray[1]).charAt(0).toUpperCase() + (splitArray[1]).slice(1); 
      return `${capitalizedFirst}-${capitalizedSecond}`
    }
  }

}
