<app-loader *ngIf="isLoading"></app-loader>
<!-- <div fxLayout="row">
    <div *ngFor="let item of scoreNumberArray"></div>
</div> -->
<div id="mainValueButton" *ngIf="'match_add' | adminPermission">
    <div class="row">
        <div class="col-md-5">
            <h3 *ngIf="scoreNumberArray.length"><b *ngFor="let item of scoreNumberArray">{{item}}&nbsp;&nbsp;</b></h3>
            <h3 *ngIf="scoreStringArray.length"><b *ngFor="let item of shortStringArray">{{item}}&nbsp;&nbsp;</b></h3>
        </div>
        <div class="col-md-2 col-3 pl-1 pr-1 shortRun">
            <label>
                <input type="checkbox" [checked]="params.wwmute" [value]="params.wwmute"
                    (change)="onWragonCheckboxChange($event)" />
                W Wheel on/off
            </label>
            <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor5"
                (click)="openwagonWheel()">
                W Wheel
            </button>
        </div>
        <div class="col-md-2 col-3 pl-1 pr-1 shortRun">
            <label>
                <input type="checkbox" [checked]="params.mute" [value]="params.mute"
                    (change)="onCheckboxChange($event)" />
                Mute & Text Off
            </label>
            <span class="iconbtn-wrapper">
                <i *ngIf="commentryStringArray.includes('Catch Drop')" (click)="tickMarkFunc('Catch Drop')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor5"
                    (click)="getButtonValue('Catch Drop',$event)">
                    Catch Drop
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreStringArray.includes('shortRun')" (click)="tickMarkFunc('shortRun')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor5"
                    (click)="getButtonValue('shortRun',$event)">
                    Short Run
                </button>
            </span>
        </div>
        <div class="col-md-3 col-6 pl-1 pr-1">
            <span class="iconbtn-wrapper">
                <i *ngIf="extraButtonArray.includes('Free Hit')" (click)="tickMarkFunc('Free Hit')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor5"
                    (click)="getButtonValue('Free Hit',$event)">
                    Free Hit
                </button>
            </span>

            <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor1"
                (click)="openPenalty()">
                Penalty
            </button>
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreStringArray.indexOf('Wkt Confirm') > -1" (click)="tickMarkFunc('Wkt Confirm')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor8"
                    (click)="openWicket()">
                    Wkt Confirm
                </button></span>
        </div>
    </div>
    <div class="row btn-group">
        <div class="col-md-3 col-3 pl-1 pr-1 flex-end">
            <span class="iconbtn-wrapper">
                <i *ngIf="extraButtonArray.includes('Ball Start')" (click)="tickMarkFunc('Ball Start')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor1"
                    (click)="getButtonValue('Ball Start',$event)">
                    Ball Start
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="extraButtonArray.includes('1 OR 2')" (click)="tickMarkFunc('1 OR 2')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor2 font-weight-bold"
                    (click)="getButtonValue('1 OR 2',$event)">
                    1 OR 2
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="extraButtonArray.includes('2 OR 3')" (click)="tickMarkFunc('2 OR 3')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor3 font-weight-bold"
                    (click)="getButtonValue('2 OR 3',$event)">
                    2 OR 3
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="extraButtonArray.includes('4 OR 6')" (click)="tickMarkFunc('4 OR 6')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor23 font-weight-bold"
                    (click)="getButtonValue('4 OR 6',$event)">
                    4 OR 6
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreStringArray.includes('wide')" (click)="tickMarkFunc('wide')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor4"
                    (click)="getButtonValue('wide',$event)">
                    Wide
                </button>
            </span>
        </div>
        <div class="col-md-3 col-3 pl-1 pr-1 flex-end">
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreNumberArray.indexOf(0) > -1" (click)="tickMarkFunc(0)">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor2 font-weight-bold"
                    (click)="getButtonValue(0,$event)">
                    0
                </button>
            </span>
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreNumberArray.includes(1)" (click)="tickMarkFunc(1)">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor5 font-weight-bold"
                    (click)="getButtonValue(1,$event)">
                    1
                </button>
            </span>
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreNumberArray.includes(2)" (click)="tickMarkFunc(2)">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor6 font-weight-bold"
                    (click)="getButtonValue(2,$event)">
                    2
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreNumberArray.includes(4)" (click)="tickMarkFunc(4)">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor7 font-weight-bold"
                    (click)="getButtonValue(4,$event)">
                    4
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreNumberArray.includes(6)" (click)="tickMarkFunc(6)">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor26 font-weight-bold"
                    (click)="getButtonValue(6,$event)">
                    6
                </button></span>
        </div>
        <div class="col-md-3 col-3 pl-1 pr-1 flex-end">
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreStringArray.includes('wicket')" (click)="tickMarkFunc('wicket')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor8"
                    (click)="getButtonValue('Wicket',$event)">
                    Wicket
                </button>
            </span>
            <span class="iconbtn-wrapper">
                <i *ngIf="extraButtonArray.includes('Hawa mei')" (click)="tickMarkFunc('Hawa mei')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor3"
                    (click)="getButtonValue('Hawa mei',$event)">
                    Hawa mei
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreNumberArray.includes(3)" (click)="tickMarkFunc(3)">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor3 font-weight-bold"
                    (click)="getButtonValue(3,$event)">
                    3
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreStringArray.includes('noball')" (click)="tickMarkFunc('noball')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor5"
                    (click)="getButtonValue('noball',$event)">
                    No Ball
                </button>
            </span>
            <span class="iconbtn-wrapper">
                <i *ngIf="extraButtonArray.includes('Hit/Maara')" (click)="tickMarkFunc('Hit/Maara')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor9"
                    (click)="getButtonValue('Hit/Maara',$event)">
                    Hit/Maara
                </button></span>
        </div>
        <div class="col-md-3 col-3 pl-1 pr-1">
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreStringArray.includes('legbye')" (click)="tickMarkFunc('legbye')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor10"
                    (click)="getButtonValue('legbye',$event)">
                    Legbye
                </button>
            </span>
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreStringArray.includes('bye')" (click)="tickMarkFunc('bye')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor11"
                    (click)="getButtonValue('bye',$event)">
                    Bye
                </button>
            </span>
            <span class="iconbtn-wrapper">
                <i *ngIf="extraButtonArray.includes('Dead Ball')" (click)="tickMarkFunc('Dead Ball')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor12"
                    (click)="getButtonValue('Dead Ball',$event)">
                    Dead Ball
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="extraButtonArray.includes('Bowler Stop')" (click)="tickMarkFunc('Bowler Stop')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor3"
                    (click)="getButtonValue('Bowler Stop',$event)">
                    Bowler Stop
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="scoreStringArray.includes('overthrow')" (click)="tickMarkFunc('overthrow')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor13"
                    (click)="getButtonValue('overthrow',$event)">
                    Overthrow
                </button>
            </span>
            <span class="iconbtn-wrapper">
                <i *ngIf="commentryStringArray.includes('Third Empire')" (click)="tickMarkFunc('Third Empire')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor14"
                    (click)="getButtonValue('Third Empire',$event)">
                    Third Empire
                </button></span>
            <span class="iconbtn-wrapper">
                <i *ngIf="commentryStringArray.includes('Appeal')" (click)="tickMarkFunc('Appeal')">✓</i>
                <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor14"
                    (click)="getButtonValue('Appeal',$event)">
                    Appeal
                </button></span>
            <button type="button" class="btn btn-primary w-100 mb-2 button_height_right btncolor8"
                (click)="openReviewModal()">
                Review
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-4 pl-1 pr-1">
            <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor15"
                [ngClass]="{'flashing_effect':scoreNumberArray.length>0||scoreStringArray.length>0}"
                (click)="getButtonValue('done',$event)">
                Done
            </button>
        </div>
        <div class="col-md-3 col-4 pl-1 pr-1">
            <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor16"
                (click)="getButtonValue('Undo',$event)">
                Undo
            </button>
        </div>
        <div class="col-md-3 col-4 pl-1 pr-1">
            <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor17"
                (click)="openDrinkModal()">
                Break
            </button>
        </div>
    </div>
</div>
<div class="modal fade" bsModal #breakModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="breakModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row pr-3">
                    <div class="col-md-12">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row pr-3">
                    <div class="col-md-12">
                        <input type="button" class="form-control btn-primary" value="Submit">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #eventDetails let-close="close">
    <div class="modal-header-event fade">
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true" style="font-size: xxx-large;margin-right: 13px;">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark wicketmodal">
        <form [formGroup]="wicketForm" (ngSubmit)="formActionAddEdit(wicketForm.value)" method="post">
            <div class="row">
                <div class="col-md-6">
                    <label class="radio-inline">
                        <input type="radio" value="batsman1"  formControlName="batsman" >&nbsp;&nbsp;
                        {{matchDetailObject.now.striker.name}} 
                    </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <label class="radio-inline">
                        <input type="radio" value="batsman2"  formControlName="batsman">&nbsp;&nbsp;&nbsp;
                        {{matchDetailObject.now.nonstriker.name}}
                    </label>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Fielder</label>
                        <div class="col-sm-8">
                            <ng-multiselect-dropdown id="bowler" [placeholder]="'Select Fielder'"
                                [settings]="dropdownSettings" [data]="bowler" [(ngModel)]="selectedItems"
                                formControlName="bowler">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-md-12 wicketDirclass icontopright" #wicketTemp id="wicketButtons">
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Caught')" (click)="tickMarkFunc('Caught')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor18 "
                        (click)="getButtonValue('Caught',$event)">
                        Caught
                    </button></span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Caught Behind')" (click)="tickMarkFunc('Caught Behind')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor19"
                        (click)="getButtonValue('Caught Behind',$event)">
                        Caught Behind
                    </button></span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('LBW')" (click)="tickMarkFunc('LBW')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor20"
                        (click)="getButtonValue('LBW',$event)">
                        LBW
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Bowled')" (click)="tickMarkFunc('Bowled')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor18"
                        (click)="getButtonValue('Bowled',$event)">
                        Bowled
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('RunOut')" (click)="tickMarkFunc('RunOut')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor20"
                        (click)="getButtonValue('RunOut',$event)">
                        RunOut
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Caught & Bowled')"
                        (click)="tickMarkFunc('Caught & Bowled')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor21"
                        (click)="getButtonValue('Caught & Bowled',$event)">
                        Caught & Bowled
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Stumped')" (click)="tickMarkFunc('Stumped')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor21"
                        (click)="getButtonValue('Stumped',$event)">
                        Stumped
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Mankading')" (click)="tickMarkFunc('Mankading')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor18"
                        (click)="getButtonValue('Mankading',$event)">
                        Mankading
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Timed Out')" (click)="tickMarkFunc('Timed Out')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor20"
                        (click)="getButtonValue('Timed Out',$event)">
                        Timed Out
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Handling Ball')" (click)="tickMarkFunc('Handling Ball')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor21"
                        (click)="getButtonValue('Handling Ball',$event) ">
                        Handling Ball
                    </button>
                </span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Obstructing field')"
                        (click)="tickMarkFunc('Obstructing field')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor19"
                        (click)="getButtonValue('Obstructing field',$event)">
                        Obstructing field
                    </button></span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Retired Hurt')" (click)="tickMarkFunc('Retired Hurt')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor21"
                        (click)="getButtonValue('Retired Hurt',$event)">
                        Retired Hurt
                    </button></span>
                <span class="iconbtn-wrapper">
                    <i *ngIf="wicketReasonArray.includes('Hitting Ball twice')"
                        (click)="tickMarkFunc('Caught & Bowled')">✓</i>
                    <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor22"
                        (click)="getButtonValue('Hitting Ball twice',$event)">
                        Hitting Ball twice
                    </button></span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 pr-1 pl-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor8"
                    (click)="getButtonValue('wicketDone',wicketTemp)">
                    Done
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #penalty let-close="close">
    <div class="modal-header-event fade">
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true" style="font-size: xxx-large;margin-right: 13px;">&times;</span>
        </button>
    </div>
    <form [formGroup]="penaltyForm" (ngSubmit)="formActionAddEditPenalty(penaltyForm.value)"
        enctype="multipart/form-data" method="post">
        <app-loader *ngIf="isLoading"></app-loader>
        <div class="modal-body text-dark wicketmodal">

            <div class="form-horizontal">
                <div class="form-group row">
                    <label class="col-md-3 col-form-label label_custom_clss" for="run">Enter Run</label>
                    <div class="col-md-9">
                        <input type="text" name="run" class="form-control number" placeholder="Enter Run"
                            autocomplete="off" formControlName="run" [ngClass]="{
                    'is-invalid':
                      penaltyForm.get('run').touched &&
                      penaltyForm.get('run').invalid}" required />
                        <div class="invalid-feedback">
                            <div>Run is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label label_custom_clss" for="studentUserName">Enter Reason</label>
                    <div class="col-md-9">
                        <input type="text" name="reason" class="form-control" placeholder="Reason" autocomplete="off"
                            formControlName="reason" [ngClass]="{
                    'is-invalid':
                      penaltyForm.get('reason').touched &&
                      penaltyForm.get('reason').invalid}" required />
                        <div class="invalid-feedback">
                            <div>Reason is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label label_custom_clss" for="runsGivenToInning">Runs Given To
                        Inning</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="runsGivenToInning" [ngClass]="{
                            'is-invalid':
                              penaltyForm.get('runsGivenToInning').touched &&
                              penaltyForm.get('runsGivenToInning').invalid}">
                            <option value="null">Select Inning</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Runs Given To Inning is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label label_custom_clss" for="runsGivenToTeam">Runs Given To
                        Team</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="runsGivenToTeam" [ngClass]="{
                    'is-invalid':
                      penaltyForm.get('runsGivenToTeam').touched &&
                      penaltyForm.get('runsGivenToTeam').invalid}" required>
                            <option value="null">Select Team</option>
                            <option *ngFor="let item of teams" value="{{item.currentTeam}}">
                                {{item.name}}
                            </option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Runs Given To Team is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label label_custom_clss" for="currentTeam">Select Team</label>
                    <div class="col-md-9">
                        <label>
                            <input type="checkbox" formControlName="isAddDelivery" />
                            Delivery
                        </label>&nbsp;&nbsp;
                        <label>
                            <input type="checkbox" formControlName="isAddBatsman"
                                (change)="onCheckBoxPenalty($event)" />
                            Batsman
                        </label>

                        <div class="invalid-feedback">
                            <div>Team is required</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-flat btn-warning update_btn_class" value="submit">
                &nbsp;&nbsp;Add &nbsp;&nbsp;</button>&nbsp;&nbsp;
        </div>
    </form>
</ng-template>
<ng-template #review let-close="close">
    <div class="modal-header-event fade">
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true" style="font-size: xxx-large;margin-right: 13px;">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark icontopright" id="reviewButtons">
        <div class="row">
            <div class="col-md-6 col-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Bowling Review')"
                        (click)="tickMarkFunc('Bowling Review')">✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Bowling Review')">
                        Bowling Review
                    </button></span>
            </div>
            <div class="col-md-6 col-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Bating Review')" (click)="tickMarkFunc('Bating Review')">✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Bating Review')">
                        Bating Review
                    </button></span>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="reviewSecondRow!=-1">
            <div class="col-md-6 col-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Out')" (click)="tickMarkFunc('Out')">✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Out')">
                        Out</button></span>
            </div>
            <div class="col-md-6 col-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Not Out')" (click)="tickMarkFunc('Not Out')">✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Not Out')">
                        Not Out
                    </button></span>
            </div>
        </div>
        <br *ngIf="reviewThirdRow!=-1">
        <div class="row" *ngIf="reviewThirdRow!=-1">
            <div class="col-md-6 col-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Review Lost')" (click)="tickMarkFunc('Review Lost')">✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Review Lost')">
                        Review Lost
                    </button></span>
            </div>
            <div class="col-md-6 col-6">
                <span class="iconbtn-wrapper">
                    <i *ngIf="reviewStringArray.includes('Umpires Call')" (click)='tickMarkFunc("Umpires Call")'>✓</i>
                    <button type="button" class="btn btn-primary w-100 button_height"
                        (click)="reviewIndexShowOrHide('Umpires Call')">
                        Umpire's Call</button></span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <!-- <button type="submit" class="btn btn-flat btn-warning update_btn_class" value="submit">
            &nbsp;&nbsp;Add &nbsp;&nbsp;</button>&nbsp;&nbsp; -->
    </div>
</ng-template>
<ng-template #break let-close="close">
    <div class="modal-header-event fade">
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true" style="font-size: xxx-large;margin-right: 13px;">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark" id="breakButtons">
        <div class="row" id="teaBreak">
            <div class="col-md-6 pl-1 pr-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor18"
                    (click)="sendSocketDataOfDrinks('Drinks')">
                    Drinks
                </button>
            </div>
            <div class="col-md-6 pl-1 pr-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor19"
                    (click)="sendSocketDataOfDrinks('Timeout')">
                    Timeout
                </button>
            </div>
            <div class="col-md-6 pl-1 pr-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor27"
                    (click)="sendSocketDataOfDrinks('Innings')">
                    Innings
                </button>
            </div>
            <div class="col-md-6 pl-1 pr-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor28"
                    (click)="sendSocketDataOfDrinks('Injury')">
                    Injury
                </button>
            </div>
            <div class="col-md-6 pl-1 pr-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor29"
                    (click)="sendSocketDataOfDrinks('Bad light')">
                    Bad light
                </button>
            </div>
            <div class="col-md-6 pl-1 pr-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor21"
                    (click)="sendSocketDataOfDrinks('Tea')">
                    Tea
                </button>
            </div>
            <div class="col-md-6 pl-1 pr-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor30"
                    (click)="sendSocketDataOfDrinks('Rain Delay')">
                    Rain Delay
                </button>
            </div>
            <div class="col-md-6 pl-1 pr-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor31"
                    (click)="sendSocketDataOfDrinks('Bad Weather')">
                    Bad Weather
                </button>
            </div>
            <div class="col-md-6 pl-1 pr-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor20"
                    (click)="sendSocketDataOfDrinks('Ground Problem')">
                    Ground Problem
                </button>
            </div>
            <div class="col-md-6 pl-1 pr-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor32"
                    (click)="sendSocketDataOfDrinks('Lunch')">
                    Lunch
                </button>
            </div>
            <div class="col-md-6 pl-1 pr-1">
                <button type="button" class="btn btn-primary w-100 mb-2 button_height btncolor32"
                    (click)="sendSocketDataOfDrinks('Dinner')">
                    Dinner
                </button>
            </div>
        </div>
        <br>
        <form [formGroup]="breakForm" (ngSubmit)="formActionAddBreak(breakForm.value)" method="post">
            <div class="row">
                <div class="col-md-12">
                    <input type="text" class="form-control w-100 button_height" maxlength="100" formControlName="name">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <input type="submit" class="form-control btn-primary w-100 button_height" value="Submit">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <!-- <button type="submit" class="btn btn-flat btn-warning update_btn_class" value="submit">
            &nbsp;&nbsp;Add &nbsp;&nbsp;</button>&nbsp;&nbsp; -->
    </div>
</ng-template>
<ng-template #wagonWheel let-close="close">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="modal-header-event fade">
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true" style="font-size: xxx-large;margin-right: 13px;">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark icontopright" id="reviewButtons">
        <div class="row">
            <div class="col-md-6 col-12">
                <h2>{{matchDetailObject.now.striker?.fullname}} ({{matchDetailObject.now.striker?.batting_style}})</h2>
                <canvas id='pieChart'></canvas>
            </div>
            <div class="col-md-6 col-12">
                <h3>{{matchDetailObject.now.bowler?.name}} ({{matchDetailObject.now.bowler?.bowling_style}})</h3>
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12 col-12">
                        <div class="matrix">
                            <table class="table table-bordered wrangonTable">
                                <tr *ngIf="wragonWheelObj.bowler_right_left_direction=='rhb'">
                                    <th colspan="6" style="text-align: center;"><p style="margin-bottom:-22px;margin-right: 491px;">Off</p> RHB <p style="margin-top: -19px;margin-left: 447px;">Leg</p> </th>
                                </tr>
                                <tr *ngIf="wragonWheelObj.bowler_right_left_direction=='lhb'">
                                    <th colspan="6" style="text-align: center;"><p style="margin-bottom:-22px;margin-right: 491px;">Leg</p> LHB <p style="margin-top: -19px;margin-left: 447px;">Off</p> </th>
                                </tr>
                                <tr>
                                    <td>Full Toss</td>
                                    <td (click)="bowler_direction('00')"><i *ngIf="wragonWheelObj.bowler_direction=='00'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('01')"><i *ngIf="wragonWheelObj.bowler_direction=='01'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('02')"><i *ngIf="wragonWheelObj.bowler_direction=='02'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('03')"><i *ngIf="wragonWheelObj.bowler_direction=='03'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('04')"><i *ngIf="wragonWheelObj.bowler_direction=='04'" style="font-size:xx-large;">✓</i></td>

                                </tr>
                                <tr>
                                    <td>Yorker</td>
                                    <td (click)="bowler_direction('10')"><i *ngIf="wragonWheelObj.bowler_direction=='10'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('11')"><i *ngIf="wragonWheelObj.bowler_direction=='11'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('12')"><i *ngIf="wragonWheelObj.bowler_direction=='12'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('13')"><i *ngIf="wragonWheelObj.bowler_direction=='13'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('14')"><i *ngIf="wragonWheelObj.bowler_direction=='14'" style="font-size:xx-large;">✓</i></td>
                                </tr>
                                <tr>
                                    <td>Full Length</td>
                                    <td (click)="bowler_direction('20')"> <i *ngIf="wragonWheelObj.bowler_direction=='20'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('21')"><i *ngIf="wragonWheelObj.bowler_direction=='21'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('22')"><i *ngIf="wragonWheelObj.bowler_direction=='22'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('23')"><i *ngIf="wragonWheelObj.bowler_direction=='23'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('24')"><i *ngIf="wragonWheelObj.bowler_direction=='24'" style="font-size:xx-large;">✓</i></td>
                                </tr>
                                <tr>
                                    <td>Good Length</td>
                                    <td (click)="bowler_direction('30')"><i *ngIf="wragonWheelObj.bowler_direction=='30'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('31')"><i *ngIf="wragonWheelObj.bowler_direction=='31'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('32')"><i *ngIf="wragonWheelObj.bowler_direction=='32'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('33')"><i *ngIf="wragonWheelObj.bowler_direction=='33'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('34')"><i *ngIf="wragonWheelObj.bowler_direction=='34'" style="font-size:xx-large;">✓</i></td>
                                </tr>
                                <tr>
                                    <td>Short of good length</td>
                                    <td (click)="bowler_direction('40')"><i *ngIf="wragonWheelObj.bowler_direction=='40'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('41')"><i *ngIf="wragonWheelObj.bowler_direction=='41'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('42')"><i *ngIf="wragonWheelObj.bowler_direction=='42'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('43')"><i *ngIf="wragonWheelObj.bowler_direction=='43'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('44')"><i *ngIf="wragonWheelObj.bowler_direction=='44'" style="font-size:xx-large;">✓</i></td>
                                </tr>
                                <tr>
                                    <td>Short Length</td>
                                    <td (click)="bowler_direction('50')"><i *ngIf="wragonWheelObj.bowler_direction=='50'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('51')"><i *ngIf="wragonWheelObj.bowler_direction=='51'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('52')"><i *ngIf="wragonWheelObj.bowler_direction=='52'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('53')"><i *ngIf="wragonWheelObj.bowler_direction=='53'" style="font-size:xx-large;">✓</i></td>
                                    <td (click)="bowler_direction('54')"><i *ngIf="wragonWheelObj.bowler_direction=='54'" style="font-size:xx-large;">✓</i></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-flat btn-warning update_btn_class" value="submit"
            (click)="sendWragonWheelData()">
            &nbsp;&nbsp;Add &nbsp;&nbsp;</button>&nbsp;&nbsp;
    </div>
</ng-template>